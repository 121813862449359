import React from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import { accountBalance } from '../../actions/account';
import { listActivations, listRanking, showBottomError, listConsumerRanking, setStep } from '../../actions/activations';
import { messageSeen, getMessages } from '../../actions/user';
import { ReactComponent as IconClose } from '../../../img/icon-close.svg'
import { ReactComponent as IconMoney } from '../../../img/icon-money.svg'
import { ReactComponent as IconPodium } from '../../../img/icon-podium.svg'
import { ReactComponent as IconStar } from '../../../img/icon-star.svg'
import { ReactComponent as ProgressBar } from '../../../img/progress_bar_line.svg'

class Dashboard extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { welcomeStep: 0 };
    //this._bind('openActionPopup', 'hideActionPopup');
  }

  componentWillMount() {
    this.props.accountBalance();
    this.props.getMessages();
    this.props.listActivations();
    this.props.listRanking('q1_22');
    //this.props.listConsumerRanking(0);
    this.props.setStep(0)
  }

  dummy() {
    
  }

  getLastDigit(num) {
    return (+(num + '').slice(-1));
  }

  render() {
    const soldDevices = this.props.account.seasons[0].soldDevices.all;
    let step = null;
    switch(this.state.welcomeStep) {
      case 0:
        step = (
          <div className="welcome-message-screen screen-0">
            <div className="close" onClick={ ()=>{ this.props.messageSeen('hello_tutorial')} }><IconClose /></div>
            <p className="welcome">Witaj<br/><span className="profile-name">{ this.props.user.user ? this.props.user.user.firstName : "" }</span></p>
            <div className="content">
              <h2>Zarabiaj każdego dnia</h2>
              <p>Polecaj urządzenia <strong>glo™</strong> pełnoletnim Konsumentom wyrobów tytoniowych.</p>
              <p>Rejestruj sprzedane urządzenia, zdobywaj nagrody i wypłacaj je w prosty i szybki sposób.</p>
              <div className="message-screen-icon">
                <IconMoney/>
              </div>
            </div>
            <div className="btn btn-white" onClick={()=>{ this.setState({ welcomeStep: 1 }) }}>Dalej</div>
          </div>
        )
        break;
      case 1:
        step = (
          <div className="welcome-message-screen screen-1">
            <div className="close" onClick={ ()=>{ this.props.messageSeen('hello_tutorial')} }><IconClose /></div>
            <p className="welcome">Witaj<br/><span className="profile-name">{ this.props.user.user ? this.props.user.user.firstName : "" }</span></p>
            <div className="content">
              <h2>Zdobywaj rangi!</h2>
              <p>Osiągnij najlepszy wynik i walcz o wysokie nagrody.</p>
              <p>Ilość sprzedanych urządzeń wpływa na Twój poziom konta. Awansuj na kolejne rangi, aby odblokować wyjątkowe bonusy.</p>
              <div className="message-screen-icon">
                <IconPodium/>
              </div>
            </div>
            <div className="btn btn-white" onClick={()=>{ this.setState({ welcomeStep: 2 }) }}>Dalej</div>
          </div>
        )
        break;
      case 2:
        step = (
          <div className="welcome-message-screen screen-2">
            <div className="close" onClick={ ()=>{ this.props.messageSeen('hello_tutorial')} }><IconClose /></div>
            <p className="welcome">Witaj<br/><span className="profile-name">{ this.props.user.user ? this.props.user.user.firstName : "" }</span></p>
            <div className="content">
              <h2>Odkryj aktywności!</h2>
              <p>W programie czekają na Ciebie dodatkowe aktywności.</p>
              <p>Bądź aktywny – rozwiązuj quizy, realizuj zadania sprzedażowe i bierz udział w konkursach, by zdobyć dodatkowe nagrody pieniężne!</p>
              <div className="message-screen-icon">
                <IconStar/>
              </div>
            </div>
            <div className="btn btn-white" onClick={ ()=>{ this.props.messageSeen('hello_tutorial')} }>Zamknij</div>
          </div>
        )
        break;
    }

    let soldTmp = soldDevices;
    if ( soldTmp > 20) {
      soldTmp = 20;
    }
    let lineOffset = 492 - (492/20)*soldTmp;

    let textVariantSale = 'sprzedanych';
    let textVariantDevice = 'urządzeń';

    if (soldDevices === 0) {

    } else if ( soldDevices === 1 ) {
      textVariantSale = 'sprzedane';
      textVariantDevice = 'urządzenie';
    } else if ( (soldDevices > 1 && soldDevices <= 4) || (this.getLastDigit(soldDevices) > 1 && this.getLastDigit(soldDevices) <= 4 ) ) {
      textVariantSale = 'sprzedane';
      textVariantDevice = 'urządzenia';
    } else if ( soldDevices >= 5 ) {
      textVariantSale = 'sprzedanych';
      textVariantDevice = 'urządzeń';
    }

    return (
      <div className="page dashboard">
        {/*<div className="season-info">
          Zakończ 1 sezon z przytupem. <br/>
          Masz czas do 30 kwietnia!
        </div> */}
        <section>
        <p className="welcome">Witaj<br/>
              <span className="profile-name">{ this.props.user.user ? this.props.user.user.firstName : "" }</span><br/>
              <a href="/" onClick={(e)=>{ e.preventDefault(); this.props.setPopup(true)}}>Twój numer BAT Partnera</a>
              <br/>
              <span className="bat-number">{ this.props.user.user ? this.props.user.user.referralCode : "" }</span>
            </p>
              
            <div className="payout-pane">
              <div className="ammount">
                <div className="desc">Zarobiłeś już</div>
                <div className="payout-columns">
                  <div className="payout-column">
                    <div className="number">{ this.props.account.profit }<span className="currency"> zł</span></div>
                    <p>ogółem</p>
                  </div>
                  <div className="payout-column">
                    <div className="number">{ this.props.account.data ? this.props.account.data.quarters.history.previous : 0 }<span className="currency"> zł</span></div>
                    <p>ostatni sezon</p>
                  </div>
                </div>
              </div>
              <div className="payout-saldo">
                { this.props.user.user 
                && this.props.user.user.dob != null 
                && this.props.user.user.streetAddress != null 
                && this.props.user.user.postalCode != null 
                && this.props.user.user.cityName != null 
                && this.props.user.user.taxNumber != null 
                && (this.props.user.user.taxOfficeName != null || this.props.user.user.foreignTaxResidence != null) ?
                <NavLink className="payout-btn-full" to="/wyplac">Wypłać</NavLink>
                :
                <NavLink to="/profil" className="payout-btn-full inactive">Zanim wypłacisz uzupełnij swój profil</NavLink>
              }
              </div>
            </div>
            <div className="stats-pane">
              <NavLink className="underline-link" to="/statystyki">Moje statystyki</NavLink>
              <div className="stats-progress">
                <div className="progress-bar">
                  <ProgressBar/>
                  <svg width="248px" height="181px" viewBox="0 0 248 181" className="fill">
                    <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="BP_dashboard_new_user" transform="translate(-64.000000, -335.000000)">
                            <g id="Group-6" transform="translate(24.000000, 278.000000)">
                                <g id="Group-4" transform="translate(14.000000, 61.000000)">
                                    <g id="progress_bar" transform="translate(30.000000, 0.000000)">
                                        <path d="M226.448028,175.006077 C235.105223,158.46279 240,139.649667 240,119.696203 C240,53.5898153 186.27417,0 120,0 C53.72583,0 0,53.5898153 0,119.696203 C0,139.651634 4.89574232,158.466499 13.5545319,175.01097" stroke="#ff8d03" strokeWidth="8" style={{ strokeDashoffset: lineOffset, strokeDasharray: 492}}></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                </div>
                <div className="sales-info">
                  <div className="sales-no">{ this.props.account.seasons[0].soldDevices.all }</div>
                  <div className="sales-name">{ textVariantSale }<br/>{textVariantDevice} <strong>glo™</strong></div>
                  { this.props.account.seasons[0].rankNext != 'zero' ? <div className="sales-description">{ "Brakuje Ci tylko "}<br/><strong>{this.props.account.seasons[0].rankNext}</strong>{" do awansu!"}</div> : '' }
                </div>
                <div className="level active level-novice">Nowicjusz</div>
                <div className={ this.props.account.seasons[0].rankId >= 1 ? "level level-beginner active" : "level level-beginner" } >Początkujący</div>
                <div className={ this.props.account.seasons[0].rankId >= 2 ? "level level-pro active" : "level level-pro" }>Profesjonalista</div>
                <div className={ this.props.account.seasons[0].rankId >= 3 ? "level level-top active" : "level level-top" }>Top Class</div>
                <div className={ this.props.account.seasons[0].rankId >= 4 ? "level level-ultimate active" : "level level-ultimate" }>Ultimate</div>
                <div className={ this.props.account.seasons[0].rankId >= 5 ? "level level-hero active" : "level level-hero" }>Hero</div>
              </div>
            </div>
        </section>
        <div className={ this.props.user.welcomeShow ? "welcome-message active" :"welcome-message" }>
          { step }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, user, account } = state
  return { screen, user, account }
};

export default withRouter(connect(
  mapStateToProps,
  {
    accountBalance,
    listActivations,
    messageSeen,
    listRanking,
    listConsumerRanking,
    showBottomError,
    getMessages,
    setStep
  }
)(Dashboard));