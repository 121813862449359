export const quiz_season_2 = [
  {
    ident: "q0",
    content: 'Czym jest VELO?',
    answers: [
      {
        ident: "a",
        a: "to saszetki nikotynowe",
      },
      {
        ident: "b",
        a: "to produkt bez tytoniu",
      },
      {
        ident: "c",
        a: "to alternatywa dla tradycyjnego palenia",
      },
      {
        ident: "d",
        a: "wszystkie odpowiedzi są poprawne",
      },
    ],
  },
  {
    ident: "q1",
    content:'Przez jaki maksymalny czas działa umieszczona saszetka nikotynowa VELO w ustach?',
    answers: [
      {
        ident: "a",
        a: "Do 15 minut",
      },
      {
        ident: "b",
        a: "Do 20 minut",
      },
      {
        ident: "c",
        a: "Do 25 minut",
      },
      {
        ident: "d",
        a: "Do 30 minut",
      },
    ],
  },
  {
    ident: "q2",
    content: 'Jakie smaki są dostępne w ofercie produktów VELO?',
    answers: [
      {
        ident: "a",
        a: "Polar Mint, Berry Frost",
      },
      {
        ident: "b",
        a: "Polar Mint, Berry Frost, Ice Cool",
      },
      {
        ident: "c",
        a: "Polar Mint, Berry Frost, Ice Cool, Freeze",
      },
      {
        ident: "d",
        a: "Polar Mint, Berry Frost, Ice Cool, Freeze, Tropic Breeze",
      },
    ],
  },
  {
    ident: "q3",
    content:'W ilu stężeniach nikotyny dostępne są w sprzedaży saszetki nikotynowe VELO?',
    answers: [
      {
        ident: "a",
        a: "1",
        t:"(4mg)"
      },
      {
        ident: "b",
        a: "2",
        t:"(4mg i 6mg)"
      },
      {
        ident: "c",
        a: "3",
        t:"(4 mg, 6mg i 10 mg)"
      },
      {
        ident: "d",
        a: "4",
        t:"(4 mg, 6mg, 10 mg oraz 10,9mg)"
      },
    ],
  },
  {
    ident: "q4",
    content:'Jakie smaki i stężenia nikotyny oferuje format MINI saszetek VELO?',
    answers: [
      {
        ident: "a",
        a: "Berry Frost i Ice Cool (4mg i 6mg)",
      },
      {
        ident: "b",
        a: "Polar Mint i Berry Frost (4mg i 6mg)",
      },
      {
        ident: "c",
        a: "Polar Mint i Ice Cool (6 mg i 10 mg)",
      },
      {
        ident: "d",
        a: "Polar Mint i Berry Frost (6mg i 10mg)",
      },
    ],
  },
  {
    ident: "q5",
    content:'W ilu wariantach smakowych występuje VELO w wersji SLIM?',
    answers: [
      {
        ident: "a",
        a: "2 – Polar Mint (10mg), Berry Frost (10mg)",
      },
      {
        ident: "b",
        a: "3 - Polar Mint (10mg), Berry Frost (6mg), Ice Cool (10mg)",
      },
      {
        ident: "c",
        a: "4 - Polar Mint (10mg), Berry Frost (10mg), Ice Cool (10mg), Freeze (10.9mg)",
      },
      {
        ident: "d",
        a: "5 - Polar Mint (4mg), Polar Mint (6mg), Berry Frost (10mg), Ice Cool (10mg), Freeze (10.9mg)",
      },
    ],
  },
  {
    ident: "q6",
    content:'Jaki kolor znajduje się na opakowaniu smaku Freeze?',
    answers: [
      {
        ident: "a",
        a: "Niebieski",
      },
      {
        ident: "b",
        a: "Zielony",
      },
      {
        ident: "c",
        a: "Szary",
      },
      {
        ident: "d",
        a: "Fioletowy",
      },
    ],
  },
  {
    ident: "q7",
    content:'Ile saszetek nikotynowych zawiera opakowanie SLIM?',
    answers: [
      {
        ident: "a",
        a: "20",
      },
      {
        ident: "b",
        a: "21",
      },
      {
        ident: "c",
        a: "24",
      },
      {
        ident: "d",
        a: "25",
      },
    ],
  },
  {
    ident: "q8",
    content:'Które zdanie jest prawdziwe?',
    answers: [
      {
        ident: "a",
        a: "Korzystanie z VELO odbywa się w 4 prostych krokach.",
      },
      {
        ident: "b",
        a: "Saszetki nikotynowe VELO wymagają przeżuwania.",
      },
      {
        ident: "c",
        a: "Opakowanie VELO nie podlega recyklingowi.",
      },
      {
        ident: "d",
        a: "Saszetki nikotynowe zawierają w swoim składzie tytoń.",
      },
    ],
  },
  {
    ident: "q9",
    content: 'Czym różni się format MINI od formatu SLIM produktu VELO',
    answers: [
      {
        ident: "a",
        a: "1",
        t: "Linia SLIM zawiera 2 razy więcej saszetek nikotynowych",
      },
      {
        ident: "b",
        a: "2",
        t: "Linia MINI charakteryzuje się mniejszym opakowaniem, natomiast rozmiar saszetek pozostaje bez zmian",
      },
      {
        ident: "c",
        a: "3",
        t: "Linia MINI przeznaczona jest głównie dla zaawansowanych konsumentów",
      },
      {
        ident: "d",
        a: "4",
        t: "Linia MINI to mniejsze saszetki i opakowanie, przeznaczona dla początkujących konsumentów",
      },
    ],
  },
];
