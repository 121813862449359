import React from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import Button from '../../Button';

function NotificationSaleBoost(props)  {

  return (
    <div className="page notification">
      <section>
        <div className="page-header">
          <h1>Pod koniec miesiąca zmieniamy zasady gry!</h1>
          <p>
            Przez 4 dni stawka za sprzedaż urządzenia glo™ będzie większa.<br/>
            Zbierasz na wakacje lub jakikolwiek inny cel? Dołożymy się!
          </p>
          <p>
            Zgarnij do swojego portfela:<br/>
            <strong>40 zł</strong> – za każde sprzedane urządzenie glo™ hyper<br/>
            lub<br/>
            <strong>45 zł</strong> – jeśli posiadasz specjalny bonus z poprzedniego sezonu.
          </p>
          <p>
            To co, zaczynamy?<br/>
            Sprzedawaj z premią i zarób więcej.
          </p>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {

  }
)(NotificationSaleBoost));