import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import Button from '../Button'
import BottomModal from '../BottomModal';
import { ReactComponent as IconCamera } from '../../../img/icon-camera.svg'
import { listRanking, listConsumerRanking } from '../../actions/activations';

function Rankings(props) {
  const [index, setIndex] = useState(0);
  const [season, setSeason] = useState(0)
  const history = useHistory();

  let createRanking = () => {
    return (
    props.activations.ranking.map((item, key)=> {
        return (
          <tr key={ key } className={ item.isMe ? 'active': ''}>
            <td className="nr">{ key+1 }</td>
            <td className="name">{ item.firstName }</td>
            <td className="score">{ item.counter }</td>
          </tr>
        )
      })
    )
  }

  return (
    <div className="page rankings">
      <section>
        <div className="page-header">
        <div className="season-header">
        <div className="season-header__top">
        <h1>Rankingi</h1>
          { index === 0 ?
            <p><strong>Sprawdź jak wypadasz na tle pozostałych uczestników programów</strong></p>
          :
            <>
              <p><strong>Sprawdź jak wypadasz na tle pozostałych uczestników programów</strong></p>
            </>
          }
        </div>
       <div>
       <div className="season__wrapper">
        <div className="season__title">Obecny:</div>
       <ul className="seasons">
        <li className={season === 0 ? "active" : ""} onClick={()=>{setSeason(0); props.listRanking('q1_22')}}>Sezon III</li>

      </ul>
      <div className="season__title">Poprzedni:</div>
      <ul className="seasons">
        <li className={season === 1 ? "active" : ""} onClick={()=>{setSeason(1); props.listRanking('s2')}}>Sezon II</li>
      </ul>
    
      </div>
      </div>

         

        </div>
       
          <ul className="buttons-list">
            <li className={index === 0 ? "active" : ""} onClick={ ()=>{ setIndex(0)} }>Sprzedaż</li>
        
          </ul>
        </div>
      </section>
      <section className={index === 0 ? "ranks active" : "ranks"}>
        <table>
          <thead>
            <tr>
              <td></td>
              <td>BAT Partner</td>
              <td>Wynik</td>
            </tr>
          </thead>
          <tbody>
            { createRanking() }
          </tbody>
        </table>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { screen, user, activations } = state
  return { screen, user, activations }
};

export default withRouter(connect(
  mapStateToProps,
  {
    listRanking
  }
)(Rankings));