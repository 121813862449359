import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { getMessages } from '../actions/user';
import { ReactComponent as IconMenu } from '../../img/icon-menu.svg';
import { ReactComponent as IconBell } from '../../img/icon-bell.svg';
import { ReactComponent as IconBack } from '../../img/icon-back.svg';
import { ReactComponent as IconClose } from '../../img/icon-close.svg';
import account from "../reducers/account";

function Header(props) {

  let messageNumber = () => {
    if ( props.user.messages.length > 0 ) {
      let unseen = props.user.messages.filter((item)=>{
        if ( item.ident === "q1_21_no_sales" ) {
          if (props.account.seasons[1] && props.account.seasons[1].soldDevices.all === 0 && item.seen === null) {
            return true;
          } else {
            return false;
          }
        }
        if ( item.ident === "q1_21_some_sales" ) {
          if (props.account.seasons[1] && props.account.seasons[1].soldDevices.all > 0 && item.seen === null) {
            return true;
          } else {
            return false;
          }
        }
        return item.seen === null;
      })
      if (unseen.length > 0) {
        return <div className="unseen">{ unseen.length }</div>
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  return (
    <header>
      { props.location.pathname != '/nowa-sprzedaz' ?
        <>
          <div className="nav-trigger" onClick={ ()=>{ props.navOpen(true) }}><IconMenu /></div>
          <div className="bell-icon">
            <NavLink to="/notyfikacje">
              <IconBell />
              { messageNumber() }
            </NavLink>
          </div>
          { props.location.pathname != '/dashboard' ? <div className="back-icon"><NavLink to="/dashboard"><IconBack /></NavLink></div> : null }
        </>
      : <div className="back-icon"><NavLink to="/dashboard"><IconClose /></NavLink></div>
      }
    </header>
  );
}

const mapStateToProps = (state) => {
  const { screen, user, account } = state
  return { screen, user, account }
};

export default withRouter(connect(
  mapStateToProps,
  {
    getMessages
  }
)(Header));