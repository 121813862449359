import React from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import { ReactComponent as IconReg } from '../../../img/icon-regulamin.svg'

class Regulaminy extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {  };
  }

  render() {
    return (
      <div className="page faqs regs">
        <section>
          <div className="page-header">
            <h1>Regulaminy</h1>
            <IconReg />
          </div>
          <ul className="regs-list">
            <li className="reg-item">
              <h3>REGULAMIN PROGRAMU „BAT PARTNERS HORECA” </h3>
              <div className="reg-item-footer">
                <a href="/assets/regulamin_bat_partners_horeca.pdf">Sprawdź regulamin</a>
                <div className="date">02.08.2021</div>
              </div>
            </li>
          </ul>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    
  }
)(Regulaminy));