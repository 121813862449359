import React,{useState} from "react";

const QuestionList = ({questionNumber,question,selectAnswer}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null)

  return (
    <div className="question-box">
      <div className="nr">
        <span>{questionNumber}</span>
      </div>
      <p className="question question--center">{question.content}</p>
      <ul className="answers answers--column">
        {question.answers.map((answer, i)=>{
            return (
              <li key={'ans-a-'+answer.ident} 
                 onClick={() => {
                selectAnswer(answer,question);
                  setSelectedAnswer(i)
                }} 
              className={ i === selectedAnswer ? 'active quiz-item quiz-item--fill' : 'quiz-item quiz-item--fill'}>
              <span>{answer.a}</span>
              {answer.t ? <span className="subtext">{answer.t}</span> : null}
              </li>
            )
          })
        }
      </ul>
    </div>
    )
  }

export default QuestionList;