import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { login, clearFlags } from '../../actions/user';
import { NavLink } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import Button from '../Button'

function LoginPage(props) {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    props.clearFlags();
  }, [])

  let validate = (e) => {
    e.preventDefault()

    if (email === "") {
      setError("Wpisz adres email")
    } else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
      setError("Nieprawidłowy adres email")
    } else if (pass === "") {
      setError("Wpisz hasło")
    } else {
      setError("")
      login()
    }
  }

  let login = () => {
    let data = {
      user: {
        emailAddress: email,
        password: pass,
        sourceHoreca: true
      }
    }
    
    props.login(data, history)
    if (window.gtag) {
      window.gtag('event', "click", { event_category: "bp-button-login", event_label: 'click' })
    }
  }

  return (
    <section className="login-page">
    	<div className="page-inner">
  			<h1>Witaj w programie <br/>BAT Partners</h1>
  			<p className="color-orange bold">Zaloguj się</p>
  			<form>
          <div className="input-item">
            <label htmlFor="email">E-mail</label>
            <input type="email" id="email" name="email" value={ email } onChange={ (e) => { setEmail(e.target.value) } }/>
          </div>
          <div className="input-item">
            <label htmlFor="password">Hasło</label>
            <input type="password" id="password" name="password" value={ pass } onChange={ (e) => { setPass(e.target.value) } }/>
          </div>
          <div className="remind">
            Nie pamiętasz hasła? > <NavLink to="/remind">Zresetuj hasło</NavLink>
          </div>
          { error !== "" ? <p className="error">{ error }</p> : null }
          <div className="">
            <Button className="btn" action={ (e) => { validate(e) } }>Zaloguj</Button>
          </div>
          <div className="login-link">
            Nie masz konta?<br/> 
            <NavLink to="/register">Zarejestruj się</NavLink>
          </div>
        </form>
  		</div>
    </section>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    login,
    clearFlags
  }
)(LoginPage));