import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import Button from '../../Button'
import { quiz } from '../../../actions/activations';
import Hyper from '../../../../img/answer_hyper@2x.png';
import Epod from '../../../../img/answer_epod@2x.png';
import Epen from '../../../../img/answer_epen@2x.png';
import { ReactComponent as True } from '../../../../img/true.svg'
import { ReactComponent as False } from '../../../../img/false.svg'

function Superseria(props) {
  const [answer_a, setAnswer_a] = useState(false);
  const [answer_b, setAnswer_b] = useState(false);
  const [answer_c, setAnswer_c] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const quizContent = [
    {
      ident: "vype0",
      answers: [
        {
          ident: "a",
          a: "2015"
        },
        {
          ident: "b",
          a: "2016"
        },
        {
          ident: "c",
          a: "2017"
        },
        {
          ident: "d",
          a: "2018"
        }
      ]
    },
    {
      ident: "vype1",
      answers: [
        {
          ident: "a",
          a: "1",
          t: "(12 mg/ml)"
        },
        {
          ident: "b",
          a: "2",
          t: "(12 mg/ml, 18 mg/ml)"
        },
        {
          ident: "c",
          a: "3",
          t: "(6 mg/ml, 12 mg/ml, 18 mg/ml)"
        },
        {
          ident: "d",
          a: "4",
          t: "(0 mg/ml, 6 mg/ml, 12 mg/ml, 18 mg/ml)"
        }
      ]
    },
    {
      ident: "vype2",
      answers: [
        {
          ident: "a",
          a: "",
          bg: Hyper
        },
        {
          ident: "b",
          a: "",
          bg: Epod
        },
        {
          ident: "c",
          a: "",
          bg: Epen
        },
        {
          ident: "d",
          a: "Żadne z powyższych",
        }
      ]
    }
  ]

  let validate = (e) => {
    e.preventDefault()
    if ( !answer_a || !answer_b || !answer_c) {
      setError("Odpowiedz na wszystkie pytania")
    } else {
      props.quiz([
        {
          "question_ident": "vype0",
          "answer_ident": answer_a.ident
        },
        {
            "question_ident": "vype1",
            "answer_ident": answer_b.ident
        },
        {
            "question_ident": "vype2",
            "answer_ident": answer_c.ident
        }
      ])
    }
  }

  return (
    <div className="page activation-page quiz-page">
      <div className={ props.activations.quizSuccess ? 'inactive' : '' }>
        <section>
          <div className="page-header">
            <h1>Obejrzyj film <br/> o Vuse</h1>
            <p>Odpowiedz na trzy proste pytania i <strong>zgarnij 2zł</strong>.</p>
            <a href="#" className="btn">Link do filmu</a>
          </div>
        </section>
        <section className="quiz-section">
          <h2>Rozwiąż quiz</h2>
          <div className="question-box">
            <div className="nr">1</div>
            <p className="question">W którym roku marka Vuse pojawiła się na polskim rynku?</p>
            <ul className="answers">
              { quizContent[0].answers.map((item, key)=>{
                  return <li key={'ans-a-'+key} onClick={()=>{setAnswer_a({ident: item.ident, index: key})}} className={ item.ident === answer_a.ident ? 'active' : ''}>{item.a}</li>
                })
              }
            </ul>
          </div>
          <div className="question-box">
            <div className="nr">2</div>
            <p className="question">W ilu stężeniach nikotyny dostępne są wkłady Vuse ePen 3?</p>
            <ul className="answers">
              { quizContent[1].answers.map((item, key)=>{
                  return (
                    <li onClick={()=>{setAnswer_b({ident: item.ident, index: key})}} className={ item.ident === answer_b.ident ? 'active' : ''}>
                      <span>{item.a}</span>
                      <span className="subtext">{item.t}</span>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="question-box">
            <div className="nr">3</div>
            <p className="question">Intuicyjne w obsłudze urządzenie bez przycisków, aktywowane poprzez zaciągnięcie. Wskaż urządzenie, o którym mowa w opisie.</p>
            <ul className="answers">
              { quizContent[2].answers.map((item, key)=>{
                  return (
                    <li onClick={()=>{setAnswer_c({ident: item.ident, index: key})}} className={ item.ident === answer_c.ident ? 'active' : ''} style={{backgroundImage: 'url('+item.bg+')'}}>
                      <span>{item.a}</span>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          { error !== "" ? <p className="error">{ error }</p> : null }
          <Button action={ (e)=>{ validate(e)}}>Sprawdź odpowiedzi</Button>
        </section>
      </div>
      <div className={ !props.activations.quizSuccess ? 'inactive' : '' }>
        <section>
          <div className="page-header">
            <h1>Gratulacje</h1>
            <div className="dark-pane">
              <p>Odpowiedziałeś poprawnie na <strong>trzy</strong> pytania. <br/> Otrzymujesz <strong>2 zł</strong>.</p>
            </div>
          </div>
        </section>
        <section className="quiz-section">
          <h2>Sprawdź odpowiedzi</h2>
          <div className="question-box">
            <div className="nr">1</div>
            <p className="question">W którym roku marka Vuse pojawiła się na polskim rynku?</p>
            <div className="user-answer">
              <p className="small">Twoja odpowiedź:</p>
              <p className="right-answer"><True /> <span>{ answer_a ? quizContent[0].answers[answer_a.index].a : '' }</span></p>
            </div>
          </div>
          <div className="question-box">
            <div className="nr">2</div>
            <p className="question">W ilu stężeniach nikotyny dostępne są wkłady Vuse ePen 3?</p>
            <div className="user-answer">
              <p className="small">Twoja odpowiedź:</p>
              <p className="right-answer"><True /> <span>{ answer_a ? quizContent[1].answers[answer_b.index].a : '' }</span></p>
            </div>
          </div>
          <div className="question-box">
            <div className="nr">3</div>
            <p className="question">Intuicyjne w obsłudze urządzenie bez przycisków, aktywowane poprzez zaciągnięcie. Wskaż urządzenie, o którym mowa w opisie.</p>
            <div className="user-answer">
              <p className="small">Twoja odpowiedź:</p>
              <p className="right-answer"><True /> <span>{ answer_a ? quizContent[2].answers[answer_c.index].a : '' }</span></p>
            </div>
          </div>
        </section> 
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { screen, user, activations } = state
  return { screen, user, activations }
};

export default withRouter(connect(
  mapStateToProps,
  {
    quiz
  }
)(Superseria));