import React from 'react';
import { connect } from "react-redux";
import {
  Switch,
  Route
} from "react-router-dom";
import BaseComponent from "../components/Helpers";
import Header from "../components/Header"
import { saveDimensionData } from '../actions/screen';
import { withRouter } from 'react-router-dom';
import Toasts from '../components/toasts/Toasts';
import LoginPage from '../components/pages/LoginPage';
import RemindPage from '../components/pages/RemindPage';
import RegisterPage from '../components/pages/RegisterPage';
import NewPasswordPage from '../components/pages/NewPasswordPage';
import Dashboard from '../components/pages/Dashboard';
import Stats from '../components/pages/Stats';
import Profile from '../components/pages/Profile';
import Payout from '../components/pages/Payout';
import Faq from '../components/pages/Faq';
import NewSale from '../components/pages/NewSale';
import Navigation from '../components/Navigation';
import BottomNav from '../components/BottomNav';
import ResetPassPage from '../components/pages/ResetPassPage';
import Superseria from '../components/pages/activations/Superseria';
import SuperseriaPro from '../components/pages/activations/SuperseriaPro';
import SuperseriaTop from '../components/pages/activations/SuperseriaTop';
import SuperseriaUltimate from '../components/pages/activations/SuperseriaUltimate';
import SuperseriaHero from '../components/pages/activations/SuperseriaHero';
import Quiz from '../components/pages/activations/Quiz';
import QuizSeason2 from '../components/pages/activations/QuizSeason2';
import UzupelnijProfil from '../components/pages/activations/Profil';
import Rankings from '../components/pages/Rankings';
import { checkSession, setToken, onLoginPopup } from '../actions/user';
import { ReactComponent as ColorLine } from '../../img/kreski.svg'
import BottomCode from '../components/BottomCode';
import BottomError from '../components/BottomError';
import BottomInfo from '../components/BottomInfo';
import { hideBottomError } from '../actions/activations';
import Regulaminy from '../components/pages/Regulaminy';
import Kontakt from '../components/pages/Kontakt';
import Notifications from '../components/pages/Notifications';
import NotificationHello from '../components/pages/notifications/NotificationHello';
import NotificationNewTerms from '../components/pages/notifications/NotificationNewTerms';
import NotificationNewTerms2 from '../components/pages/notifications/NotificationNewTerms2';
import NotificationNewSeason from '../components/pages/notifications/NotificationNewSeason';
import NotificationNewSeasonSales from '../components/pages/notifications/NotificationNewSeasonSales';
import ShortcutInfoPage from '../components/pages/ShortcutInfoPage';
import HyperVusePage from '../components/pages/HyperVusePage';
import ScrollToTop from '../components/ScrollToTop';
import NotificationQuiz from '../components/pages/notifications/NotificationQuiz';
import NotificationSaleBoost from '../components/pages/notifications/NotificationSaleBoost';

class App extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { navOpen: false, bottomPopup: false, bottomCodePopup: false };
  }

  componentWillMount() {
    this.updateDimensions();
    //this.props.checkSession(this.props.history, true)
    if (this.props.location.search.indexOf("reset") !== -1 ) {
      let token = this.props.location.search.split("=")[1];
      this.props.setToken(token);
      this.props.history.push("/nowe-haslo", { from: this.props.location })
    } else {
      this.props.checkSession(this.props.history, true)
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions() {
    this.props.saveDimensionData({
      height: window.innerHeight,
      width: window.innerWidth
    })
  }

  render() {

    return (
      <>
      <div className="app">
        <ScrollToTop/>
        <ColorLine className="color-line" preserveAspectRatio="none"/>
        { (this.props.location.pathname != '/' && this.props.location.pathname != '/remind' && this.props.location.pathname != '/register' && this.props.location.pathname != '/aktywacje/quiz-season-2' && this.props.location.pathname != '/nowe-haslo') ? <Header location={ this.props.location } navOpen={ (open) => { this.setState({ navOpen: open }) }} /> : null }
        <Navigation open={this.state.navOpen} close={ ()=>{ this.setState({ navOpen: false }) }} />
        <Switch>
          <Route path={`${process.env.PUBLIC_URL}/`} exact>
            <LoginPage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/remind`} exact>
            <RemindPage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/register`} exact>
            <RegisterPage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/nowe-haslo`} exact>
            <NewPasswordPage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/dashboard`} exact>
            <Dashboard setPopup={ (popupState)=>{ this.setState({ bottomCodePopup: popupState }) } } />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/statystyki`} exact>
            <Stats/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/profil`} exact>
            <Profile setPopup={ (popupState)=>{ this.setState({ bottomCodePopup: popupState }) } } />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/wyplac`} exact>
            <Payout/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/faq`} exact>
            <Faq/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/nowa-sprzedaz`} exact>
            <NewSale setPopup={ (popupState)=>{ this.setState({ bottomCodePopup: popupState }) } }  />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/reset-hasla`} exact>
            <ResetPassPage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/ranking`} exact>
            <Rankings/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/aktywacje/superseria`} exact>
            <Superseria/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/aktywacje/superseria-pro`} exact>
            <SuperseriaPro/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/aktywacje/superseria-top`} exact>
            <SuperseriaTop/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/aktywacje/superseria-ultimate`} exact>
            <SuperseriaUltimate/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/aktywacje/superseria-hero`} exact>
            <SuperseriaHero/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/aktywacje/quiz`} exact>
            <Quiz/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/aktywacje/quiz-season-2`} exact>
            <QuizSeason2/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/aktywacje/uzupelnij-profil`} exact>
            <UzupelnijProfil/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/regulamin`} exact>
            <Regulaminy/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/kontakt`} exact>
            <Kontakt/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/notyfikacje`} exact>
            <Notifications/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/notyfikacje/hello`} exact>
            <NotificationHello/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/notyfikacje/new-terms`} exact>
            <NotificationNewTerms/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/notyfikacje/new-terms-2`} exact>
            <NotificationNewTerms2/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/notyfikacje/new-season`} exact>
            <NotificationNewSeason/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/notyfikacje/new-season-sales`} exact>
            <NotificationNewSeasonSales/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/notyfikacje/quiz`} exact>
            <NotificationQuiz/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/notyfikacje/sale-boost`} exact>
            <NotificationSaleBoost/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/skrot-info`} exact>
            <ShortcutInfoPage/>
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/poznaj-nasze-urzadzenia`} exact>
            <HyperVusePage/>
          </Route>
        </Switch>
        { (this.props.location.pathname != '/' && this.props.location.pathname != '/remind' && this.props.location.pathname != '/register' && this.props.location.pathname != '/nowa-sprzedaz' && this.props.location.pathname != '/nowe-haslo') ? <BottomNav popup={ this.state.bottomPopup } setPopup={ (popupState)=>{ this.setState({ bottomPopup: popupState }) } } /> : null }
        <BottomCode popup={ this.state.bottomCodePopup } setPopup={ (popupState)=>{ this.setState({ bottomCodePopup: popupState }) } } user={ this.props.user } />
        <BottomError redirect={this.props.activations.bottomError.redirect} popup={ this.props.activations.bottomError.show } message={ this.props.activations.bottomError.message } setPopup={ this.props.hideBottomError } />
        { <BottomInfo popup={ this.props.user.onLoginPopup } setPopup={ (popupState)=>{ this.props.onLoginPopup(popupState) } } />}
        <Toasts/>
      </div>
    </>
    )
  }
}

const mapStateToProps = (state) => {
  const { screen, user, activations } = state
  return { screen, user, activations }
};

export default withRouter(connect(
  mapStateToProps,
  {
    saveDimensionData,
    checkSession,
    setToken,
    hideBottomError,
    onLoginPopup
  }
)(App));