import React from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import { ReactComponent as IconClose } from '../../../img/icon-close.svg'

class Faq extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { activeQuestion: null };
    this._bind('setActiveQuestion');

    this.questions = [
      {
        "q": "Co to jest program BAT Partners?",
        "a": "Program wsparcia sprzedaży produktów British American Tobacco w punktach sprzedaży. Detalista za wykonywanie zadań (polecenie produktów BAT objętych programem, rejestracja sprzedanych urządzeń w systemie oraz wykonywanie dodatkowych zadań) otrzymuje nagrody pieniężne."
      },
      {
        "q": "Jak długo trwa program BAT Partners HORECA?",
        "a": <p>Program BAT Partners HORECA zaczyna działanie 1 sierpnia 2021, a kończy się 31 grudnia 2021. Do programu można dołączyć w każdym momencie jego trwania.</p>
      },
      {
        "q": "Komu dedykowany jest program BAT Partners HORECA?",
        "a": <p>Program BAT Partners HORECA dedykowany jest pracownikom punktów HORECA, oferujących w sprzedaży produkty firmy BAT (glo™ Hyper+)</p>
      },
      {
        "q": "Co mogę otrzymać za zarejestrowanie sprzedaży w programie?",
        "a": <p>Za skuteczne polecenie zakończone sprzedażą urządzenia i jego rejestrację można otrzymać nagrody pieniężne: nagrody wynoszą <strong>25 zł</strong> za każde sprzedane urządzenie glo™ HYPER+ <strong> dodatkowe 5 zł</strong> za każde skuteczne polecenie konsumentowi rejestracji na stronie <a href='www.batpartners.pl/konsument' target='_blank'>www.batpartners.pl/konsument</a>.<br/></p>
      },
      {
        "q": "Czy w programie BAT Partners HORECA przewidziana jest oferta dla Konsumenta??",
        "a": <p>W ramach programu BAT Partners Uczestnik programu może polecić Konsumentowi rejestrację na stronie <a href='www.batpartners.pl/konsument' target='_blank'>www.batpartners.pl/konsument</a>. Za rejestrację na stronie Konsument otrzyma SMS z <strong>kodem promocyjnym</strong> na zakup trzech opakowań wkładów neo™ w cenie jednego.</p>
      },
      {
        "q": "Jakie dodatkowe nagrody można uzyskać w programie BAT Partners HORECA?",
        "a": <p>Uczestnicy programu mają też możliwość zdobycia dodatkowych nagród za realizację zadań. Program podzielony jest na dwa sezony: sierpień - październik oraz listopad - grudzień.<br/><br/>W każdym z sezonów Uczestnik programu ma możliwość zdobycia nagród za realizację wyznaczonego celu sprzedażowego (zdobycie rangi):
        <ul>
          <li>15 zł za sprzedaż trzech dowolnych Produktów BAT w czasie trwania sezonu;</li>
          <li>40 zł za sprzedaż sześciu dowolnych Produktów BAT w czasie trwania sezonu;</li>
          <li>50 zł za sprzedaż dziesięciu dowolnych Produktów BAT w czasie trwania sezonu;</li>
          <li>60 zł za sprzedaż piętnastu dowolnych Produktów BAT w czasie trwania sezonu;</li>
          <li>100 zł za sprzedaż dwudziestu dowolnych Produktów BAT w czasie trwania sezonu.</li>
        </ul>
        <span>Dodatkowo, za sprzedaż co najmniej piętnastu dowolnych Produktów BAT w czasie trwania sezonu Uczestnik dostanie dodatkowe 5 zł za każde sprzedane urządzenie w następnym sezonie.</span>
        </p>
      },
      {
        "q": "W jaki sposób zarejestrować sprzedane urządzenie?",
        "a": <p>Na stronie głównej programu wybierz opcję „nowa sprzedaż” (pomarańczowy przycisk z białym plusem widoczny u dołu ekranu). W celu dodania nowego urządzenia należy <strong>zeskanować numer tego urządzenia</strong> umieszczony z tyłu opakowania (oznaczony jako my glo™ number dla urządzeń glo™ HYPER). Alternatywnie, można też wprowadzić kod z pudełka ręcznie.</p>
      },
      {
        "q": "Na której stronie rejestruje się Konsument?",
        "a": <p>Konsument rejestruje się na stronie <a href="www.batpartners.pl/konsument." target="_blank">www.batpartners.pl/konsument</a>.</p>
      },
      {
        "q": "Po co Konsument rejestruje się na stronie programu?",
        "a": <p>W nagrodę za rejestrację Konsument otrzyma kod promocyjny na trzy opakowania wkładów neo™. Dodatkowo, po zarejestrowaniu będzie otrzymywał on także dodatkowe kody promocyjne oraz informacje o dostępnych promocjach.</p>
      },
      {
        "q": "Czy jeden Konsument może zarejestrować więcej niż jedno urządzenie?",
        "a": <p>Każdy Konsument może zarejestrować się w bazie wyłącznie jeden raz – niezależnie od ilości zakupionych urządzeń.</p>
      },
      {
        "q": "Jak otrzymać nagrodę za program BAT Partners?",
        "a": <p>Nagrodę można otrzymać <strong>wyłącznie po uzupełnieniu profilu o dane podatkowe</strong> – możesz je uzupełnić w dowolnym momencie w zakładce <a href="www.batpartners.pl/profil" target="_blank">www.batpartners.pl/profil</a> – pamiętaj, wprowadzenie poprawnych danych jest warunkiem otrzymania nagrody! <strong>Za uzupełnienie danych otrzymasz dodatkową nagrodę w wysokości 5 zł.</strong>
        Proces zdobywania nagród:
        <ul>
          <li>Zaproponuj Konsumentowi skorzystanie z oferty programowej BAT Partners</li>
          <li>Sprzedając urządzenie Konsumentowi zeskanuj jego kod lub wprowadź go ręcznie</li>
          <li>Nagroda za sprzedane urządzenie wyświetli się na stronie głównej programu, a Ty otrzymasz na podany numer telefonu potwierdzenie dokonania sprzedaży</li>
          <li>Przejdź na stronę główną i wybierz opcję „wypłać” – zostaniesz przeniesiony na stronę billon</li>
          <li>Twój numer telefonu uzupełni się automatycznie, a na podany numer otrzymasz kod SMS – wprowadź go na stronie, na której się znajdujesz</li>
          <li>Wskaż numer konta, na który chcesz dokonać wpłaty środków zdobytych w programie. Konto zostanie zasilone w ciągu kilku dni roboczych.</li>
        </ul>
        </p>
      },
      {
        "q": "Czy ilość nagród, które mogę otrzymać w ramach programu BAT Partners jest limitowana?",
        "a": <p>Każdy uczestnik programu może zarejestrować <strong>8 urządzeń</strong> w ciągu 24 godzin. Liczba nagród przewidziana w programie nie jest limitowana.</p>
      },
      {
        "q": "Otrzymuję komunikat, że numer urządzenia, który wprowadziłem, jest błędny – co robić?",
        "a": <p>Zweryfikuj, czy poprawnie wprowadziłeś numer urządzenia – zalecamy skanowanie numerów w celu uniknięcia błędów przy rejestracji. Jeżeli wprowadzony numer jest zgodny z tym na pudełku –zdjęcie numeru urządzenia i prześlij je na adres <a href="mailto:kontakt@batpartners.pl">kontakt@batpartners.pl</a>. Nie martw się – na podstawie zdjęcia numeru będziesz mógł zarejestrować to urządzenie później.<br/><br/>
        Nie wprowadzaj numeru wielokrotnie – po pięciu próbach wprowadzenia nieistniejącego / już wprowadzonego numeru Twoje konto zostanie czasowo zablokowane.
        </p>
      },
      {
        "q": "Moje konto zostało zablokowane – co robić?",
        "a": <p>W sytuacji, gdy konto Użytkownika zostanie zablokowane, będziemy kontaktować się z jego właścicielem telefonicznie. Pamiętaj – zgodnie z regulaminem możesz zostać poproszony o przedstawienie dokumentów potwierdzających sprzedaż urządzeń zarejestrowanych na Twoim koncie przed jego odblokowaniem.</p>
      },
      {
        "q": "Czy powinienem otrzymać od Was informację PIT-11?",
        "a": <p>PIT-11 zostanie wystawiony osobom, których suma nagród <strong>wypłaconych w 2021</strong> roku przekroczy 200PLN, a które nie prowadzą działalności gospodarczej. </p>
      },
      {
        "q": "W jakiej formie powinienem otrzymać od Was informację PIT-11?",
        "a": <p>PIT-11 został wysłany w wersji papierowej na adres podany w procesie rejestracji na stronie www.batpartners.pl lub drogą elektroniczną.</p>
      },
      {
        "q": "Czy sprzedaż została naliczona jeśli nie otrzymałem/otrzymałam SMSa z potwierdzeniem?",
        "a": <p>SMS jest potwierdzeniem sprzedaży, jeśli Detalista go nie otrzymał, to znaczy, że sprzedaż nie została zarejestrowana.</p>
      },
      {
        "q": "Do czego potrzebne są moje dane?",
        "a": <p>Dane kontaktowe – numer telefonu i adres e-mail będą niezbędne do prawidłowej obsługi programu. Na podany przy rejestracji numer telefonu Detalista będzie otrzymywał między innymi hasła do logowania w usłudze billon. Adres e-mail będzie potrzebny przy ewentualnym odzyskiwaniu konta.<br/><br/>
        Dane podatkowe – są niezbędne do prawidłowego wystawienia PIT-11 (albo, w przypadku osób zarejestrowanych jako prowadzące działalność gospodarczą, stanowią potwierdzenie, że nagrody uzyskane w programie zostaną rozliczone jako przychód z działalności gospodarczej).
        </p>
      },
      {
        "q": "Dlaczego nie mogę zmienić numeru telefonu / adresu e-mail / imienia / nazwiska podanego przy rejestracji?",
        "a": <p>Ze względów bezpieczeństwa niektórych danych pozwalających na identyfikację uczestnika programu nie można zmienić w zakładce PROFIL. Jeżeli któraś z tych danych uległa zmianie – skontaktuj się z infolinią i poproś o zmianę danych.</p>
      }
    ]
  }

  setActiveQuestion(index) {
    if (this.state.activeQuestion === index) {
      this.setState({ activeQuestion: null })
    } else {
      this.setState({ activeQuestion: index })
    }
  }

  render() {
    return (
      <div className="page faq">
        <section>
          <div className="page-header">
            <h1>FAQ</h1>
          </div>
          <ul className="qa-list">
            { this.questions.map((item, key)=>{
                return ( 
                  <li key={"question-"+key} className={ this.state.activeQuestion === key ? 'active list-item' : 'list-item' }>
                    <div className="question" onClick={ ()=>{ this.setActiveQuestion(key) } }>{ item.q }</div>
                    <div className="answer">{ item.a }</div>
                  </li>
                )
              })
            }
          </ul>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    
  }
)(Faq));