import React from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import Button from '../../Button';

function NotificationQuiz(props)  {

  return (
    <div className="page notification">
      <section>
        <div className="page-header">
          <h1>Materiały o glo™ i Vuse są już dostępne!</h1>
          <p>Zapoznaj się z nimi, a następnie sprawdź swój poziom wiedzy. Rozwiąż quiz i zgarnij 5 zł.</p>
          <p>Prosimy o zapoznanie się z regulaminem, który jest dostępny <a href="/assets/regulamin_bat_parteners_quiz_glo_vuse.pdf" target="_blank">tutaj</a>.</p>
          <NavLink className="btn" to="/poznaj-nasze-urzadzenia">Przejdź do quizu</NavLink>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {

  }
)(NotificationQuiz));