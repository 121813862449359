import React, { useState,useEffect} from "react";
import { connect } from "react-redux";
import { withRouter ,useHistory} from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import Button from '../../Button'
import QuestionList from '../../QuestionList'
import { quiz } from '../../../actions/activations';
import {quiz_season_2 as questions} from '../../../data/question';
import { ReactComponent as IconClose } from '../../../../img/icon-close.svg';
import moment from "moment";


function QuizSeazon2(props) {
  const history = useHistory();
  const [selectedAnswers,setSelectedAnswers] = useState([]);
  const [filled, setFilled] = useState(false);


  useEffect(() => {

    if(selectedAnswers.length === questions.length && checkTime()) {
      setFilled(true);
    }else {
      setFilled(false)
    }
  }, [selectedAnswers])

  const selectAnswer = (answer,question) =>  {
      const isSelected = selectedAnswers.find((selected) => selected.question_ident === question.ident);
      const selected = [...selectedAnswers];
      
      if(!isSelected) {
        const newSelectedAnswer = {
          answer_ident:answer.ident,
          question_ident:question.ident,
        }
        setSelectedAnswers([...selected,newSelectedAnswer])
      } else {
        const updateAnswer = selected.map((item) => {
          if (item.question_ident === question.ident) {
            return {
             answer_ident:answer.ident,
              question_ident:question.ident,
            };
          }
          return item;
        });
        setSelectedAnswers(updateAnswer);
      }
  }

  const validate = (e) => {
    e.preventDefault() 
    if(filled) {
      props.quiz(selectedAnswers,'quiz_velo')
    }
    return;
  }

  const completed = (e) => {
    let activation = props.activations.list.filter((item, key) => {
      return item.ident === 'quiz_velo'
    })

    return activation[0].completed
  }

  const checkTime = (e) => {
    let activation = props.activations.list.filter((item, key) => {
      return item.ident === 'quiz_velo'
    })

    if ( activation[0].solutions.length > 0) {
      const nextAttempt = activation[0].solutions[activation[0].solutions.length-1].payload.next_attempt;
      if (moment().diff(moment(nextAttempt), 'minutes') >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  return (
    <div className="page activation-page quiz-page">
    <button onClick={() => history.push('/dashboard')} className="quiz-page__btn">
      <IconClose/>
    </button>
      <div className={ props.activations.quizSuccess ? 'inactive' : '' }>
        <section>
          <div className="page-header">
            <h1>Rozwiąż quiz</h1>
            <p>Odpowiedz na dziesięć prostych pytań i <strong>zgarnij 5 zł.</strong></p>
          </div>
        </section>
        <section className="quiz-section">
          {questions.map((question,idx) => 
          <QuestionList key={question.ident}
           questionNumber={idx + 1} 
           selectAnswer={selectAnswer}
           selectedAnswers={selectedAnswers}
           question={question} />
           )}
          { completed() ? '' :
            <Button type={!filled ? 'disabled' : 'btn'} action={ (e)=>{ validate(e)}}>Sprawdź odpowiedzi</Button>
          }
        </section>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { screen, user, activations } = state
  return { screen, user, activations }
};

export default withRouter(connect(
  mapStateToProps,
  {
    quiz
  }
)(QuizSeazon2));