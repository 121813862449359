import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import Button from '../Button'
import BottomModal from '../BottomModal';
import { ReactComponent as IconCamera } from '../../../img/icon-camera.svg';
import { ReactComponent as InfoIcon } from '../../../img/info-icon.svg';
import { registerDevice, uploadFile, setStep } from '../../actions/activations';
import activations from "../../reducers/activations";

function NewSale(props) {
  //let [step, setStep] = useState(0);
  const [modal, setModal] = useState(false)
  const [serial, setSerial] = useState('');
  const [error, setError] = useState("");
  const [file, setFile] = useState({name:""});
  const [infoBox, setInfoBox] = useState(false)
  const history = useHistory();

  let validate = (e) => {
    e.preventDefault()

    if (serial === "") {
      setError("Wpisz numer urządzenia")
    } else if (serial.length < 6 ) {
      setError("Błędny numer urządzenia")
    } else {
      setError("")
      props.registerDevice(serial)
    }
  }
                                                                                                      // Numer urządzenia jest umieszczony z tyłu opakowania urządzenia glo (tm) HYPER i oznaczony jako my glo (tm) number
  return (
    <div className="page new-sale">
      <section className={ (props.activations.step === 0 || props.activations.step === undefined) && !props.activations.registerSuccess ? 'active' : ''}>
        <div className="page-header">
          <h1 onClick={ () => { setInfoBox(!infoBox) } }>Wprowadź <br/>numer urządzenia<InfoIcon /></h1>
          <p className={ infoBox ? "info-box open" : "info-box" } onClick={ () => { setInfoBox(false) } }>Numer urządzenia jest umieszczony z tyłu opakowania,  urządzenia <strong>glo™</strong> i <strong>glo™</strong> HYPER number dla urządzeń <strong>glo™</strong> HYPER i my Vuse number dla urządzeń Vuse ePen</p>
        </div>
        <label className="scan-btn" htmlFor="userPic">
          <div className="scan-icon"><IconCamera/></div>
          <span>Zeskanuj</span>
          <p>{ file.name }</p>
        </label>
        <input type="file" name="userPic" id="userPic" onChange={(e)=>{ setFile(e.target.files[0]) }}/>
        <div className="enter-link" onClick={ ()=>{ setModal(true) }}>
          Chcę wpisać
        </div>
        <Button className="btn" action={ (e) => { 
          e.preventDefault(); 
          props.uploadFile(file);
          props.setStep(2);
          if (window.gtag) {
            window.gtag('event', "click", { event_category: "bp-button-register-device", event_label: 'step1' })
          }
        } }>Wyślij</Button>
      </section>
      <section className={ props.activations.step === 1 && !props.activations.registerSuccess && !props.activations.deviceLimit ? 'active' : ''}>
        <div className="page-header">
          <h1>Wpisz numer urządzenia</h1>
        </div>
        <p>Numer urządzenia jest umieszczony z tyłu opakowania urządzenia <strong>glo™ HYPER+</strong> i oznaczony jako my <strong>glo™</strong> number</p>
        <div className="input-item">
          <label htmlFor="serial">Numer urządzenia</label> 
          <input type="text" id="serial" name="serial" value={ serial } onChange={ (e) => { setSerial(e.target.value) } }/>
        </div>
        { error !== "" ? <p className="error">{ error }</p> : null }
        <Button className="btn" action={ (e) => { 
            validate(e);
            if (window.gtag) {
              window.gtag('event', "click", { event_category: "bp-button-register-device", event_label: 'serial-confirm' })
            }
          } }>Potwierdzam</Button>
      </section>
      <section className={ props.activations.step === 2 && !props.activations.registerSuccess && !props.activations.deviceLimit ? 'active' : ''}>
        { !props.activations.ocrSuccess ?
          <div className="page-header">
            <h3>Przetwarzanie...</h3>
          </div>
        :
          <>
            <div className="page-header">

              <h3>Numer zeskanowany poprawnie</h3>
            </div>
            <div className="align-bottom">
              <div className="serial-box">
                <p>Sprzedałem urządzenie o numerze seryjnym:</p>
                <h4>{ props.activations.serial ? props.activations.serial.serialNumber : ''}</h4>
              </div>
              <Button className="btn" action={ (e) => { 
                props.registerDevice(props.activations.serial.serialNumber) ;
                if (window.gtag) {
                  window.gtag('event', "click", { event_category: "bp-button-register-device", event_label: 'scan-confirm' })
                }
                } }>Potwierdzam</Button>
            </div>
          </>
        }
      </section>
      <section className={ props.activations.registerSuccess && !props.activations.deviceLimit ? 'active' : ''}>
        <div className="page-header">
          <h1>Gratulacje sprzedaży</h1>
        </div>
        <p><strong>{props.activations.payout} zł</strong> właśnie zasiliło Twoje konto BAT Partners.</p>
        <p><a href="/" onClick={(e)=>{ e.preventDefault(); props.setPopup(true)}}>Wyświetl klientowi swój numer BAT Partnera</a></p>
        <div className="earn-more-pane">
          <h3>Chcesz zarobić więcej?</h3>
          <p>Zarejestruj konsumenta <br/>na stronie <a href="https://batpartners.pl/konsument">batpartners.pl/konsument</a></p>
          <NavLink className="white-link" to="/ranking">Sprawdź ranking</NavLink>
        </div>
      </section>
      <section className={ !props.activations.registerSuccess && props.activations.deviceLimit ? 'active' : ''}>
        <div className="page-header">
          <h1>Ale tempo!</h1>
        </div>
        <p>Niestety, zarejestrowałeś już dzisiaj 8 urządzeń i&nbsp;osiągnąłeś swój dzienny limit.</p>
        <p><strong>Zapisz numer i zarejestruj jutro.</strong></p>
        <div className="earn-more-pane">
          <h3>Chcesz zarobić więcej?</h3>
          <p>Zarejestruj konsumenta <br/>na stronie <a href="https://batpartners.pl/konsument">batpartners.pl/konsument</a></p>
          <NavLink className="white-link" to="/ranking">Sprawdź ranking</NavLink>
        </div>
      </section>
      <BottomModal open={ modal } hidePopup={ ()=>{ setModal(false) } }>
        <div className="popup-inner popup-new-sale">
					<h3>Chcesz sam wpisać numer urządzenia?</h3>
          <p>Pamiętaj, że przy wpisywaniu numeru urządzenia konieczne jest zachowanie dokumentu potwierdzającego sprzedaż.</p>
					<Button className="btn" action={ () => { props.setStep(1); setModal(false) } }>Tak, chcę wpisać</Button>
				</div>
      </BottomModal>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { screen, user, activations } = state
  return { screen, user, activations }
};

export default withRouter(connect(
  mapStateToProps,
  {
    registerDevice,
    uploadFile,
    setStep
  }
)(NewSale));