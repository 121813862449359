import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { register } from '../../actions/user';
import { useHistory } from "react-router-dom";
import { ReactComponent as IconCircle } from '../../../img/circle.svg'
import { ReactComponent as IconChrome } from '../../../img/ic-chrome.svg'
import { ReactComponent as IconSafari } from '../../../img/ic-safari.svg'
import { ReactComponent as IconAdd } from '../../../img/ic-add.svg'
import { ReactComponent as IconShare } from '../../../img/ic-share.svg'
import { ReactComponent as IconAddDroid } from '../../../img/ic-add_a.svg'
import { ReactComponent as IconDots } from '../../../img/ic-dots.svg'
import PanSlider from "../PanSlider";

function ShortcutInfoPage(props) {
  const [index, setIndex] = useState(0)
  const history = useHistory();

  return (
    <section className="page shortcut">
      <div className="page-header">
        <h1>Dodawanie skrótu na&nbsp;pulpit</h1>
        <p>Sprawdź jak ułatwić sobie życie dodając ikonę BAT Partners bezpośrednio na ekran początkowy swojego telefonu.</p>
        <ul className="indexed-nav">
          <li className={index === 0 ? "active" : ""} onClick={()=>{setIndex(0);}}>Android</li>
          <li className={index === 1 ? "active" : ""} onClick={()=>{setIndex(1);}}>iOS</li>
        </ul>
      </div>
       { index === 0 ? <div>
        <PanSlider 
          slideWidth={ props.screen.width } 
          slideCount={ 4 } 
          showDotNav={ true }>
          <ul className="cards">
            <li>
              <div className="card">
                <div className="nr">
                  <IconCircle/>
                  <span>1</span>
                </div>
                <div className="card-inner">
                  <p><strong>Otwórz stronę batpartners.pl w przeglądarce Google Chromena swoim telefonie.</strong></p>
                  <p>Ten krok masz już za sobą, jeśli właśnie używasz Google Chrome!</p>
                </div>
                <IconChrome/>
              </div>
            </li>
            <li>
              <div className="card">
                <div className="nr">
                  <IconCircle/>
                  <span>2</span>
                </div>
                <div className="card-inner">
                  <p><strong>Otwórz Opcje</strong></p>
                  <p>Żeby to zrobić wybierz ikonę trzech kropek w prawym dolnym rogu.</p>
                </div>
                <IconDots/>
              </div>
            </li>
            <li>
              <div className="card">
                <div className="nr">
                  <IconCircle/>
                  <span>3</span>
                </div>
                <div className="card-inner">
                <p><strong>Z listy dostępnych opcji wybierz „Dodaj do ekranu głównego”</strong></p>
                </div>
                <IconAddDroid/>
              </div>
            </li>
            <li>
              <div className="card">
                <div className="nr">
                  <IconCircle/>
                  <span>4</span>
                </div>
                <div className="card-inner">
                <p><strong>Postępuj zgodnie z&nbsp;instrukcjami sugerowanymi przez Google Chrome</strong></p>
                </div>
                <IconChrome/>
              </div>
            </li>
          </ul>
        </PanSlider>
      </div> : null }
      { index === 1 ? <div>
        <PanSlider 
          slideWidth={ props.screen.width } 
          slideCount={ 4 } 
          showDotNav={ true }>
          <ul className="cards">
            <li>
              <div className="card">
                <div className="nr">
                  <IconCircle/>
                  <span>1</span>
                </div>
                <div className="card-inner">
                  <p><strong>Otwórz stronę batpartners.pl w przeglądarce Safarina swoim telefonie.</strong></p>
                  <p>Ten krok masz już za sobą, jeśli właśnie używasz Safari!</p>
                </div>
                <IconSafari/>
              </div>
            </li>
            <li>
              <div className="card">
                <div className="nr">
                  <IconCircle/>
                  <span>2</span>
                </div>
                <div className="card-inner">
                  <p><strong>Wybierz ikonę udostępniania</strong></p>
                  <p>Jest ona widoczna na środku dolnego paska nawigacji.</p>
                </div>
                <IconShare/>
              </div>
            </li>
            <li>
              <div className="card">
                <div className="nr">
                  <IconCircle/>
                  <span>3</span>
                </div>
                <div className="card-inner">
                <p><strong>Wybierz „Do ekranu początkowego”</strong></p>
                <p>Zobaczysz tę opcję, jeśli wysuniecz do góry okno, które pojawiło sięna dole ekranu.</p>
                </div>
                <IconAdd/>
              </div>
            </li>
            <li>
              <div className="card">
                <div className="nr">
                  <IconCircle/>
                  <span>4</span>
                </div>
                <div className="card-inner">
                <p><strong>Potwierdź klikając „Dodaj”</strong></p>
                <p>Link znajduje się w prawym górnym rogu</p>
                </div>
                <IconSafari/>
              </div>
            </li>
          </ul>
        </PanSlider>
      </div> : null }
    </section>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    register
  }
)(ShortcutInfoPage));