import React from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import { ReactComponent as IconClose } from '../../../img/icon-close.svg'
import { payout } from '../../actions/account';
import moment from 'moment';

class Payout extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {  welcomeMessage: true, welcomeStep: 0 };
    //this._bind('openActionPopup', 'hideActionPopup');
  }

  render() {
    return (
      <div className="page payout">
        <section>
          <div className="page-header">
            <h1>Wypłać</h1>
          </div>
          <div className="payout-pane">
            <div className="ammount">
              <div className="desc">Zarobiłeś już</div>
              <div className="number">{ this.props.account.profit }<span className="currency">zł</span></div>
              {/*<p className="last-season-info">
                Ostatni sezon: <br/><span>{ this.props.account.data ? this.props.account.data.quarters.history.previous : 0 }</span>
              </p>
              <NavLink className="stats-link" to="/statystyki">Moje statystyki</NavLink>*/}
            </div>
            <div className="payout-saldo">
              <div className="desc">Saldo</div>
              <div className="number">{ this.props.account.balance }<span className="currency">zł</span></div>
              { this.props.user.user 
                && this.props.user.user.dob != null 
                && this.props.user.user.streetAddress != null 
                && this.props.user.user.postalCode != null 
                && this.props.user.user.cityName != null 
                && this.props.user.user.taxNumber != null 
                && (this.props.user.user.taxOfficeName != null || this.props.user.user.foreignTaxResidence != null) ?
                <div className={this.props.account.balance === 0 ? "payout-btn btn inactive" : "payout-btn btn"} onClick={
                  ()=>{ 
                    if(this.props.account.balance>=0 && !this.props.account.paymentInProgress){
                      this.props.payout()
                      if (window.gtag) {
                        window.gtag('event', "click", { event_category: "bp-button-payout", event_label: 'click' })
                      }
                    } }}>Wypłać</div>
                :
                <>  
                  <NavLink to="/profil">Zanim wypłacisz uzupełnij swój profil</NavLink>
                  <div className="payout-btn btn inactive">Wypłać</div>
                </>
              }
            </div>
            <div className="disclaimer">
              <p>Po kliknięciu “Wypłać” otrzymasz wiadomość SMSz linkiem prowadzącym do zewnętrznego systemu <strong>Billon</strong> umożliwiającego łatwą wypłatę środków.</p>
            </div>
          </div>
        </section>
        <section className="history-section">
          <h3>Historia wypłat</h3>
          <table>
            <tbody>
              { this.props.account.payoutHistory.map((item, key)=> {
                return (
                  <tr key={ key }>
                    <td className="date">{ moment(item.created.date).format('DD.MM.YYYY')}</td>
                    <td className="score">{ -item.value+' zł'}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, user, account } = state
  return { screen, user, account }
};

export default withRouter(connect(
  mapStateToProps,
  {
    payout
  }
)(Payout));