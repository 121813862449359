import React from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import { accountBalance } from '../../actions/account';
import { messageSeen, getMessages } from '../../actions/user';

class Notifications extends BaseComponent {

  constructor(props) {
    super(props)
  }

  componentWillMount() {

  }

  render() {
  
    let messages = this.props.user.messages.filter((item)=>{
      if (item.ident === 'hello_tutorial') {
        return false;
      }
      if (item.ident === 'q1_21_no_sales') {
        if (this.props.account.seasons[1].soldDevices.all === 0) {
          return true;
        } else {
          return false;
        }
      }
      if (item.ident === 'q1_21_some_sales') {
        if (this.props.account.seasons[1].soldDevices.all > 0) {
          return true;
        } else {
          return false;
        }
      }
      
      return true;
    })

    return (
      <div className="page notifications">
        <section>
          <p className="welcome">Witaj<br/>
            <span className="profile-name">{ this.props.user.user ? this.props.user.user.firstName : "" }</span><br/>
          </p>
          <ul className="notifications-list">
            { messages.reverse().map((item, key)=>{
              switch(item.ident) {
                case 'hello_fill_your_profile':
                  return (
                    <li className={ item.seen != null ? "notification-item" : "notification-item new"}>
                      <NavLink to="/notyfikacje/hello" exact>
                        <h3>Witaj w programie BAT Partners!</h3>
                        <p>Na początek uzupełnij swój profil i zyskaj 5 zł.</p>
                      </NavLink>
                    </li>
                  )
                  case 'new_terms_04_21':
                    return (
                      <li key={item.ident} className={ item.seen != null ? "notification-item" : "notification-item new"}>
                        <NavLink to="/notyfikacje/new-terms" exact onClick={()=>{this.props.messageSeen(item.ident)}}>
                          <h3>Drogi BAT Partnerze!</h3>
                          <p>Informujemy, że w związku z par 11 pkt 3.e wprowadzamy zmiany w Regulaminie BAT Partners.</p>
                        </NavLink>
                      </li>
                    )
                  case 'q1_21_no_sales':
                    return (
                      <li key={item.ident} className={ item.seen != null ? "notification-item" : "notification-item new"}>
                        <NavLink to="/notyfikacje/new-season" exact onClick={()=>{this.props.messageSeen(item.ident)}}>
                          <h3>Premiera drugiego sezonu BAT Partners!</h3>
                          <p>01.05.2021 - 31.07.2021</p>
                        </NavLink>
                      </li>
                    )
                  case 'q1_21_some_sales':
                    return (
                      <li key={item.ident} className={ item.seen != null ? "notification-item" : "notification-item new"}>
                        <NavLink to="/notyfikacje/new-season-sales" exact onClick={()=>{this.props.messageSeen(item.ident)}}>
                          <h3>Premiera drugiego sezonu BAT Partners!</h3>
                          <p>01.05.2021 - 31.07.2021</p>
                        </NavLink>
                      </li>
                    )
                  case 'task_quiz':
                    return (
                      <li className={ item.seen != null ? "notification-item" : "notification-item new"}>
                        <NavLink to="/notyfikacje/quiz" exact onClick={()=>{this.props.messageSeen(item.ident)}}>
                          <h3>Materiały o glo™ i Vuse są już dostępne!</h3>
                          <p>Zapoznaj się z nimi, a następnie sprawdź swój poziom wiedzy. Rozwiąż quiz i zgarnij 5 zł.</p>
                        </NavLink>
                      </li>
                    )
                  case 'new_terms_05_24':
                    return (
                      <li className={ item.seen != null ? "notification-item" : "notification-item new"}>
                        <NavLink to="/notyfikacje/new-terms-2" exact onClick={()=>{this.props.messageSeen(item.ident)}}>
                          <h3>Drogi BAT Partnerze!</h3>
                          <p>Dokonaliśmy zmian w regulaminie programu.</p>
                        </NavLink>
                      </li>
                    )
                  case 'glo_sale_boost':
                    return (
                      <li className={ item.seen != null ? "notification-item" : "notification-item new"}>
                        <NavLink to="/notyfikacje/sale-boost" exact onClick={()=>{this.props.messageSeen(item.ident)}}>
                          <h3>Pod koniec miesiąca zmieniamy zasady gry!</h3>
                          <p>Przez 4 dni stawka za sprzedaż urządzenia glo™ będzie większa.</p>
                        </NavLink>
                      </li>
                    )
                default:
                  return <li></li>
              }
            })}
          </ul>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, user, account } = state
  return { screen, user, account }
};

export default withRouter(connect(
  mapStateToProps,
  {
    accountBalance,
    getMessages,
    messageSeen
  }
)(Notifications));