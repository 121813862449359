import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { register } from "../../actions/user";
import { useHistory } from "react-router-dom";
import IconSek from "../../../img/hyper-vuse/icon-15-sek@2x.png";
import IconStart from "../../../img/hyper-vuse/ic-gotowosc@2x.png";
import IconBattery from "../../../img/hyper-vuse/ic_bateria@2x.png";
import IconDevice from "../../../img/hyper-vuse/ic_smaki@2x.png";
import IconPackage from "../../../img/hyper-vuse/ic_dystrybucja@2x.png";
import IconClick from "../../../img/hyper-vuse/ikona-click@2x.png";
import HyperTop from "../../../img/hyper-vuse/img@2x.png";
import HyperBot from "../../../img/hyper-vuse/img_bot@2x.jpg";
import VuseTop from "../../../img/hyper-vuse/img-vuse@2x.jpg";
import VuseBot from "../../../img/hyper-vuse/vusebg@3x.jpg";
import PanSlider from "../PanSlider";
import GreenFlavor from "../../../img/hyper-vuse/green@2x.png";
import ScarletFlavor from "../../../img/hyper-vuse/scarlet@2x.png";
import SunsetFlavor from "../../../img/hyper-vuse/sunset@2x.png";
import VioletFlavor from "../../../img/hyper-vuse/violet@2x.png";
import IceFlavor from "../../../img/hyper-vuse/ice@2x.png";
import DarkFlavor from "../../../img/hyper-vuse/dark@2x.png";
import ArcticBlueClick from "../../../img/hyper-vuse/arctic@2x.png";
import SummerYellowClick from "../../../img/hyper-vuse/summer@2x.png";
import TerracottaFlavor from "../../../img/hyper-vuse/terracotta@2x.png";
import PowerGreen from "../../../img/hyper-vuse/shape_green.svg";
import PowerScarlet from "../../../img/hyper-vuse/shape_scarlet.svg";
import PowerArcticFirst from "../../../img/hyper-vuse/shape_arctic-first.svg";
import PowerArcticSecound from "../../../img/hyper-vuse/shape_arctic-secound.svg";
import PowerSummerFirst from "../../../img/hyper-vuse/shape_summer-first.svg";
import PowerSummerSecound from "../../../img/hyper-vuse/shape_summer-secound.svg";
import PowerViolet from "../../../img/hyper-vuse/shape_violet.svg";
import PowerSunset from "../../../img/hyper-vuse/shape_sunset.svg";
import PowerIce from "../../../img/hyper-vuse/shape_ice.svg";
import NeoStick from "../../../img/hyper-vuse/about-the-stick@2x.png";
import IconHand from "../../../img/hyper-vuse/img-hand@2x.png";
import IconShield from "../../../img/hyper-vuse/img-shield@2x.png";
import IconDiamond from "../../../img/hyper-vuse/img-diamond@2x.png";
import VuseLogo from "../../../img/hyper-vuse/vuse-logo.svg";

import PackMango from "../../../img/hyper-vuse/blushed_mango.png";
import PackPeach from "../../../img/hyper-vuse/classic_peach.png";
import PackMint from "../../../img/hyper-vuse/creamy_mint.png";
import PackCrisp from "../../../img/hyper-vuse/crisp_mint.png";
import PackCherry from "../../../img/hyper-vuse/dark_cherry.png";
import PackApple from "../../../img/hyper-vuse/fresh_apple.png";
import PackSpearmint from "../../../img/hyper-vuse/fresh_spearmint.png";
import PackJust from "../../../img/hyper-vuse/just_mint.png";
import PackNutty from "../../../img/hyper-vuse/nutty_tobacco.png";
import PackStrawberry from "../../../img/hyper-vuse/original_strawberry.png";
import PackPeppermint from "../../../img/hyper-vuse/peppermint_tobacco.png";
import PackBerries from "../../../img/hyper-vuse/wild_berries.png";

import PolarMint from "../../../img/hyper-vuse/velo/polar-mint@3x.png";
import PolarMint2 from "../../../img/hyper-vuse/velo/polar-mint2@3x.png";
import PolarMint3 from "../../../img/hyper-vuse/velo/polar-mint3@3x.png";
import BerryFrost from "../../../img/hyper-vuse/velo/berry-frost@3x.png";
import BerryFrost2 from "../../../img/hyper-vuse/velo/berry-frost2@3x.png";
import BerryFrost3 from "../../../img/hyper-vuse/velo/berry-frost3@3x.png";
import IceCool3 from "../../../img/hyper-vuse/velo/ice-cool3@3x.png";
import Freeze4 from "../../../img/hyper-vuse/velo/freeze4@3x.png";
import PackshotBg from "../../../img/hyper-vuse/velo/packshot-and-bg@3x.png";
import IconMouth from "../../../img/hyper-vuse/velo/icon-mouth@3x.png";
import IconMouth2 from "../../../img/hyper-vuse/velo/icon-mouth2@3x.png";
import IconTimer from "../../../img/hyper-vuse/velo/icon-timer@3x.png";
import IconGum from "../../../img/hyper-vuse/velo/icon-hand@3x.png";
import VeloLogo from "../../../img/hyper-vuse/velo/velo-logo.svg";

function HyperVusePage(props) {
  let startPage = 1;
  if (props.location.hash === "#velo") startPage = 1;
  const [index, setIndex] = useState(startPage);
  const history = useHistory();

  return (
    <section className="page hyper-vuse">
      <ul className="indexed-nav">
        <li
          className={index === 0 ? "active" : ""}
          onClick={() => {
            setIndex(0);
          }}
        >
          glo™ hyper
        </li>
        <li
          className={index === 1 ? "active" : ""}
          onClick={() => {
            setIndex(1);
          }}
        >
          Velo
        </li>
      </ul>
      <div className={index === 0 ? "hyper-page active" : "hyper-page"}>
        <div className="page-header">
          <h1>glo™ hyper+</h1>
          <p>
            Doświadcz satysfakcji z podgrzewania tytoniu dzięki zaawansowanej
            technologii indukcyjnej. Nowy stylowy design, technologia znana
            z&nbsp;glo™&nbsp;hyper.
          </p>
        </div>
        <div className="hyper-top-img">
          <img src={HyperTop} />
        </div>
        <div className="hyper-slider-top">
          <PanSlider
            slideWidth={props.screen.width}
            slideCount={6}
            showDotNav={false}
            arrowDirection={true}
          >
            <ul className="cards">
              <li>
                <div className="card">
                  <div className="card-head">
                    <div className="card-icon">
                      <img src={IconSek} />
                    </div>
                  </div>
                  <div className="card-inner">
                    <p>
                      <strong>Bez dymu, bez popiołu*</strong>
                    </p>
                    <p>
                      Prawdziwy tytoń i zaawansowana technologia w stylowym
                      wydaniu
                    </p>
                    <p className="small">
                      *W porównaniu do tradycyjnie palonych papierosów. Nie
                      oznacza to, że produkt ten jest&nbsp;mniej szkodliwy niż
                      inne wyroby tytoniowe
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <div className="card-icon">
                      <img src={IconDevice} />
                    </div>
                  </div>
                  <div className="card-inner">
                    <p>
                      <strong>2 tryby pracy</strong>
                    </p>
                    <p>
                      Dzięki dwóm trybom STANDARD i&nbsp;BOOST&nbsp;możesz
                      kontrolować intensywność&nbsp;podgrzewania tytoniu
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <div className="card-icon">
                      <img src={IconStart} />
                    </div>
                  </div>
                  <div className="card-inner">
                    <p>
                      <strong>Szybki start</strong>
                    </p>
                    <p>
                      Możliwość podgrzania 3 wkładów neo™&nbsp;jeden&nbsp;po
                      drugim
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <div className="card-icon">
                      <img src={IconPackage} />
                    </div>
                  </div>
                  <div className="card-inner">
                    <p>
                      <strong>Bez przerw na ładowanie</strong>
                    </p>
                    <p>
                      Gotowość do użycia już w 15 sekund
                      dla&nbsp;trybu&nbsp;BOOST
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <div className="card-icon">
                      <img src={IconBattery} />
                    </div>
                  </div>
                  <div className="card-inner">
                    <p>
                      <strong>Pojemna bateria</strong>
                    </p>
                    <p>Do 20 sesji na jednym&nbsp;ładowaniu urządzenia</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <div className="card-icon">
                      <img src={IconClick} />
                    </div>
                  </div>
                  <div className="card-inner">
                    <p>
                      <strong>Szeroki wybór wkładów neo™</strong>
                    </p>
                    <p>
                      Bogactwo 9 wariantów smakowych
                      -&nbsp;w&nbsp;tym&nbsp;z&nbsp;podwójną&nbsp;kapsułą,
                      mentolowe,&nbsp;owocowe&nbsp;i&nbsp;tytoniowe
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </PanSlider>
        </div>
        <h2>Nowe smaki, więcej opcji wyboru</h2>
        <div className="hyper-slider-flavors">
          <PanSlider
            slideWidth={props.screen.width}
            slideCount={9}
            showDotNav={false}
            arrowDirection={true}
          >
            <ul className="cards">
              <li>
                <div
                  className="card"
                  style={{ backgroundImage: "url(" + ArcticBlueClick + ")" }}
                >
                  <div className="card-head">
                    <h3>Arctic Blue Click</h3>
                    <p>Zbalansowany smak tytoniu i podwójny klik</p>
                  </div>
                  <div className="card-inner">
                    <img className="icon-power" src={PowerArcticFirst} />
                    <p>
                      <strong>
                        doświadczenie <br />
                        mroźnego mentolu
                      </strong>
                    </p>
                  </div>
                  <div className="card-inner">
                    <img className="icon-power" src={PowerArcticSecound} />
                    <p>
                      <strong>słodka miętowa nuta</strong>
                    </p>
                  </div>
                  <div className="power-tobacco">
                    <p>
                      <strong>Intensywność</strong>
                    </p>
                    <div className="power-tobacco-wrapper">
                      <span className="power-tobacco__bullet power-tobacco__bullet--blue"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--blue"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--blue"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--blue"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--blue"></span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="card"
                  style={{ backgroundImage: "url(" + SummerYellowClick + ")" }}
                >
                  <div className="card-head">
                    <h3>Summer Yellow Click</h3>
                    <p>
                      Łagodna baza tytoniowa z dwiema kapsułami do kliknięcia
                    </p>
                  </div>
                  <div className="card-inner">
                    <img className="icon-power" src={PowerSummerFirst} />
                    <p>
                      <strong>nuta słodkiego melona</strong>
                    </p>
                  </div>
                  <div className="card-inner">
                    <img className="icon-power" src={PowerSummerSecound} />
                    <p>
                      <strong>nuta tropikalnych owoców</strong>
                    </p>
                  </div>
                  <div className="power-tobacco">
                    <p>
                      <strong>Intensywność</strong>
                    </p>
                    <div className="power-tobacco-wrapper">
                      <span className="power-tobacco__bullet power-tobacco__bullet--green"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--green"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--green"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--green-empty"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--green-empty"></span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="card"
                  style={{ backgroundImage: "url(" + IceFlavor + ")" }}
                >
                  <div className="card-head">
                    <h3>Ice Click</h3>
                    <p>
                      Doświadczenie intensywnej świeżości na bazie łagodnego
                      tytoniu
                    </p>
                  </div>
                  <div className="card-inner">
                    <img className="icon-power" src={PowerIce} />
                    <p>
                      <strong>
                        fala jeszcze bardziej miętowego efektu z&nbsp;nutą
                        słodyczy
                      </strong>
                    </p>
                  </div>
                  <div className="power-tobacco">
                    <p>
                      <strong>Intensywność</strong>
                    </p>
                    <div className="power-tobacco-wrapper">
                      <span className="power-tobacco__bullet power-tobacco__bullet--ice"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--ice"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--ice"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--ice"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--ice"></span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="card"
                  style={{ backgroundImage: "url(" + GreenFlavor + ")" }}
                >
                  <div className="card-head">
                    <h3>Green Click</h3>
                    <p>
                      Łagodna tytoniowa baza z&nbsp;kremowymi i&nbsp;owocowymi
                      akcentami
                    </p>
                  </div>
                  <div className="card-inner">
                    <img className="icon-power" src={PowerGreen} />
                    <p>
                      <strong>
                        delikatne chłodzące orzeźwienie z&nbsp;nutą&nbsp;mięty
                      </strong>
                    </p>
                  </div>
                  <div className="power-tobacco">
                    <p>
                      <strong>Intensywność</strong>
                    </p>
                    <div className="power-tobacco-wrapper">
                      <span className="power-tobacco__bullet power-tobacco__bullet--dark-green"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--dark-green"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--dark-green"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--dark-green-empty"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--dark-green-empty"></span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="card"
                  style={{ backgroundImage: "url(" + ScarletFlavor + ")" }}
                >
                  <div className="card-head">
                    <h3>Scarlet Click</h3>
                    <p>
                      Tytoniowa baza z&nbsp;kremowymi i&nbsp;owocowymi nutami.
                    </p>
                  </div>
                  <div className="card-inner">
                    <img className="icon-power" src={PowerScarlet} />
                    <p>
                      <strong>
                        słodki posmak z nutą czerwonych&nbsp;owoców
                      </strong>
                    </p>
                  </div>
                  <div className="power-tobacco">
                    <p>
                      <strong>Intensywność</strong>
                    </p>
                    <div className="power-tobacco-wrapper">
                      <span className="power-tobacco__bullet power-tobacco__bullet--red"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--red"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--red"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--red-empty"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--red-empty"></span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="card"
                  style={{ backgroundImage: "url(" + VioletFlavor + ")" }}
                >
                  <div className="card-head">
                    <h3>Violet Click</h3>
                    <p>Prawdziwy tytoń z nutą jagodową.</p>
                  </div>
                  <div className="card-inner">
                    <img className="icon-power" src={PowerViolet} />
                    <p>
                      <strong>
                        słodki i świeży, jeszcze bardziej jagodowy smak
                      </strong>
                    </p>
                  </div>
                  <div className="power-tobacco">
                    <p>
                      <strong>Intensywność</strong>
                    </p>
                    <div className="power-tobacco-wrapper">
                      <span className="power-tobacco__bullet power-tobacco__bullet--dark-blue"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--dark-blue"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--dark-blue"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--dark-blue-empty"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--dark-blue-empty"></span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="card"
                  style={{ backgroundImage: "url(" + SunsetFlavor + ")" }}
                >
                  <div className="card-head">
                    <h3>Sunset Click</h3>
                    <p>Tytoń z kremowymi i&nbsp;karmelowymi nutami</p>
                  </div>
                  <div className="card-inner">
                    <img className="icon-power" src={PowerSunset} />
                    <p>
                      <strong>
                        słodkie i orzeźwiające doświadczenie z&nbsp;nutą ananasa
                      </strong>
                    </p>
                  </div>
                  <div className="power-tobacco">
                    <p>
                      <strong>Intensywność</strong>
                    </p>
                    <div className="power-tobacco-wrapper">
                      <span className="power-tobacco__bullet power-tobacco__bullet--yellow"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--yellow"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--yellow-empty"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--yellow-empty"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--yellow-empty"></span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="card"
                  style={{ backgroundImage: "url(" + TerracottaFlavor + ")" }}
                >
                  <div className="card-head">
                    <h3>Terracotta Tobacco</h3>
                    <p>
                      Prawdziwy tytoń z&nbsp;kremowymi i&nbsp;owocowymi nutami.
                    </p>
                  </div>
                  <div className="card-inner"></div>
                  <div className="power-tobacco">
                    <p>
                      <strong>Intensywność</strong>
                    </p>
                    <div className="power-tobacco-wrapper">
                      <span className="power-tobacco__bullet power-tobacco__bullet--brown"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--brown"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--brown"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--brown-empty"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--brown-empty"></span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="card"
                  style={{ backgroundImage: "url(" + DarkFlavor + ")" }}
                >
                  <div className="card-head">
                    <h3>Dark Tobacco</h3>
                    <p>
                      Złożone i intenstywne doświadczenie tytoniu
                      z&nbsp;pikantnymi nutami drzewnymi.
                    </p>
                  </div>
                  <div className="card-inner"></div>
                  <div className="power-tobacco">
                    <p>
                      <strong>Intensywność</strong>
                    </p>
                    <div className="power-tobacco-wrapper">
                      <span className="power-tobacco__bullet power-tobacco__bullet--dark-brown"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--dark-brown"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--dark-brown"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--dark-brown"></span>
                      <span className="power-tobacco__bullet power-tobacco__bullet--dark-brown-empty"></span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </PanSlider>
        </div>
        {/* <div style={{ backgroundColor: "#fff", paddingTop: "40px" }}>
          <h2 className="small">
            Wkłady neo™ zostały zaprojektowane by dostarczać unikatowego
            doświadczenia tytoniu
          </h2>
          <img className="about-the-stick-img" src={NeoStick} />
          <ul className="about-the-stick-list">
            <li>
              <span className="nr">1</span>
              <div className="content">
                <h3>Prawdziwy tytoń</h3>
                <p>
                  Wysokiej jakości liście tytoniu tworzące mieszankę
                  zaprojektowaną i przygotowaną specjalnie do podgrzewania w
                  glo™
                </p>
              </div>
            </li>
            <li>
              <span className="nr">2</span>
              <div className="content">
                <h3>Bibułka papierosowa</h3>
                <p>okala tytoń, mocując go filtra</p>
              </div>
            </li>
            <li>
              <span className="nr">3</span>
              <div className="content">
                <h3>Segment chłodzący</h3>
                <p>
                  to przestrzeń oddzielająca tytoń od filtra, w której powstaje
                  para tytoniowa.
                </p>
              </div>
            </li>
            <li>
              <span className="nr">4</span>
              <div className="content">
                <h3>Laserowe otwory wentylacyjne</h3>
                <p>pomagają w chłodzeniu powstałej pary tytoniowej.</p>
              </div>
            </li>
            <li>
              <span className="nr">5</span>
              <div className="content">
                <h3>Filtr</h3>
                <p>
                  kontroluje temperaturę i smak powstałej pary tytoniowej. W
                  wariantach kapsułowych, tutaj znajduje się kaspuła.
                </p>
              </div>
            </li>
            <li>
              <span className="nr">6</span>
              <div className="content">
                <h3>Tuba</h3>
                <p>
                  filtr zakończony tubą – która tworzy dodatkową przestrzeń do
                  rozproszenia pary tytoniowej przed zaciągnięciem się
                </p>
              </div>
            </li>
          </ul>
          <div className="page-header">
            <h2>glo™ hyper+</h2>
            <p>Połączenie nowoczesnej technologii i eleganckiego designu.</p>
          </div>
          <div className="hyper-bot-img">
            <img src={HyperBot} />
          </div>
          <div className="hyper-slider-bot">
            <PanSlider
              slideWidth={props.screen.width}
              slideCount={4}
              showDotNav={false}
            >
              <ul className="cards">
                <li>
                  <div className="card">
                    <div className="card-inner">
                      <h3>Dwa tryby pracy</h3>
                      <p>
                        Dzięki 2 trybom STANDARD i BOOST możesz kontrolować
                        intensywność podgrzewania tytoniu.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="card">
                    <div className="card-inner">
                      <h3>Szybki start</h3>
                      <p>Gotowość do użycia w 15 sekund w trybie BOOST.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="card">
                    <div className="card-inner">
                      <h3>Bez przerw na ładowanie</h3>
                      <p>Możliwość podgrzania 3 wkładów neo™ jeden po drugim</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="card">
                    <div className="card-inner">
                      <h3>Pojemna bateria</h3>
                      <p>
                        Do 20 sesji na jednym ładowaniu*
                        <br />
                        <span className="small">
                          * po pełnym naładowaniu baterii, uzależnione od
                          sposobu użytkowania
                        </span>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </PanSlider>
          </div>
        </div> */}
      </div>

      {/*<div className={index === 1 ? "vuse-page active" : "vuse-page"}>
        <div className="page-header">
          <h1>
            Elegancki i łatwy w&nbsp;obsłudze e-papieros w&nbsp;systemie
            zamkniętym
          </h1>
          <p>Pewność użytkowania i nowoczesny design</p>
        </div>
        <div className="hyper-top-img">
          <img src={VuseTop} />
        </div>
        <div className="vuse-slider-top">
          <PanSlider
            slideWidth={props.screen.width}
            slideCount={3}
            showDotNav={false}
            arrowDirection={true}
          >
            <ul className="cards">
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={IconHand} />
                  </div>
                  <div className="card-inner">
                    <h3>Pewność wyboru</h3>
                    <p>
                      Marka Vuse oferuje szeroki wybór produktów oraz wysoką
                      jakość potwierdzoną badaniami niezależnych ekspertów.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={IconShield} />
                  </div>
                  <div className="card-inner">
                    <h3>Bezpieczeństwo i wiarygodność</h3>
                    <p>
                      Urządzenia Vuse są testowane przez niezależne,
                      certyfikowane laboratoria.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={IconDiamond} />
                  </div>
                  <div className="card-inner">
                    <h3>Bogactwo wyboru</h3>
                    <p>
                      Vuse jest liderem kategorii systemów zamkniętych oferując
                      największy wybór aromatów w różnych stężeniach nikotyny.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </PanSlider>
        </div>
        <div className="page-header">
          <h1>Bogactwo aromatów</h1>
          <p>
            Wkłady Vuse ePen dostępne są w 12 wariantach smakowych
            o&nbsp;różnych stężeniach nikotyny. Wkłady są kompatybilne
            z&nbsp;oboma urządzeniami.
          </p>
        </div>
        <div className="vuse-slider-mid">
          <PanSlider
            slideWidth={props.screen.width}
            slideCount={12}
            showDotNav={false}
            arrowDirection={true}
          >
            <ul className="cards">
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={PackMango} />
                  </div>
                  <div className="card-inner">
                    <h3>Blushed Mango</h3>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={PackPeach} />
                  </div>
                  <div className="card-inner">
                    <h3>Classic Peach</h3>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={PackMint} />
                  </div>
                  <div className="card-inner">
                    <h3>Creamy Mint</h3>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={PackCrisp} />
                  </div>
                  <div className="card-inner">
                    <h3>CrispMint</h3>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={PackCherry} />
                  </div>
                  <div className="card-inner">
                    <h3>Dark Cherry</h3>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={PackApple} />
                  </div>
                  <div className="card-inner">
                    <h3>Fresh Apple</h3>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={PackSpearmint} />
                  </div>
                  <div className="card-inner">
                    <h3>Fresh Spearmint</h3>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={PackJust} />
                  </div>
                  <div className="card-inner">
                    <h3>Just Mint</h3>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={PackNutty} />
                  </div>
                  <div className="card-inner">
                    <h3>Nutty Tobacco</h3>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={PackStrawberry} />
                  </div>
                  <div className="card-inner">
                    <h3>Original Strawberry</h3>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={PackPeppermint} />
                  </div>
                  <div className="card-inner">
                    <h3>Peppermint Tobacco</h3>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={PackBerries} />
                  </div>
                  <div className="card-inner">
                    <h3>Wild Berries</h3>
                  </div>
                </div>
              </li>
            </ul>
          </PanSlider>
        </div>
        <div style={{ backgroundColor: "#fff", paddingTop: "40px" }}>
          <div className="page-header">
            <h1>Vype ePen3 to teraz Vuse&nbsp;ePen</h1>
            <p>
              Nowa nazwa i design, <br />
              to samo doświadczenie z&nbsp;e-palenia.
            </p>
          </div>
          <div className="hyper-top-img">
            <img src={VuseBot} />
          </div>
          <div className="vuse-slider-bot">
            <PanSlider
              slideWidth={props.screen.width}
              slideCount={4}
              showDotNav={false}
              arrowDirection={true}
            >
              <ul className="cards">
                <li>
                  <div className="card">
                    <div className="card-head">
                      <img src={VuseLogo} />
                    </div>
                    <div className="card-inner">
                      <h3>
                        E-papieos Vuse ePen wyróżnia ergonomiczna konstrukcja
                        oraz bateria o pojemności 650&nbsp;mAh –&nbsp;dla
                        całodziennego użytkowania*
                      </h3>
                      <p className="small">
                        *Na podstawie testów laboratoryjnych Vype. Żywotność
                        baterii może się różnić w zależności od użytkowania i
                        innych czynników.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="card">
                    <div className="card-head">
                      <img src={VuseLogo} />
                    </div>
                    <div className="card-inner">
                      <h3>
                        Vuse ePen to e-papieros działający w prostym systemie
                        zamkniętym
                      </h3>
                      <p>
                        Zatrzaskowy system wymiany wkładów - bez rozlewania.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="card">
                    <div className="card-head">
                      <img src={VuseLogo} />
                    </div>
                    <div className="card-inner">
                      <h3>Testowany 100 razy</h3>
                      <p>pod względem jakości.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="card">
                    <div className="card-head">
                      <img src={VuseLogo} />
                    </div>
                    <div className="card-inner">
                      <h3>Do 99% mniej substancji toksycznych*</h3>
                      <p className="small">
                        *W porównaniu do tradycyjnie palonych papierosów. Nie
                        oznacza to, że produkt ten jest mniej szkodliwy niż inne
                        wyroby tytoniowe. Potwierdzone badaniami z wyłączeniem
                        wkładów z aromatem: Crisp Mint 6mg/ml; Dark Cherry,
                        Fresh Spearmint, Just Mint, Peppermint Tobacco, Creamy
                        Mint 12mg/ml & 18mg/ml.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </PanSlider>
          </div>
        </div>
      </div>*/}

      <div className={index === 1 ? "velo-page active" : "velo-page"}>
        <div className="page-header">
          <h1>Velo</h1>
          <p>Nowoczesne doświadczenie nikotyny kiedy chcesz, gdzie chcesz</p>
        </div>
        <div className="velo-top-img">
          <img src={PackshotBg} />
          <div className="velo-top-img__content">
            <p className="velo-top-img__header">Pełna dyskrecja</p>
            <span className="velo-top-img__desc">
              Używanie VELO w 4 krokach
            </span>
          </div>
        </div>
        <div className="velo-slider-top">
          <PanSlider
            slideWidth={props.screen.width}
            slideCount={4}
            arrowDirection={true}
            showDotNav={false}
          >
            <ul className="cards">
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={IconMouth} />
                  </div>
                  <div className="card-inner">
                    <h3>Krok 1 z 4</h3>
                    <p>
                      Należy umieścić jedną saszetkę w ustach, pod górną wargą
                      lub tak jak jest Ci wygodnie.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={IconMouth2} />
                  </div>
                  <div className="card-inner">
                    <h3>Krok 2 z 4</h3>
                    <p>
                      Konsument może poczuć mrowienie co oznacza, że nikotyna
                      się wchłania.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={IconTimer} />
                  </div>
                  <div className="card-inner">
                    <h3>Krok 3 z 4</h3>
                    <p>
                      Należy pozostawić saszetkę w&nbsp;ustach by cieszyć się
                      działaniem produktu. Saszetka zachowuje swoje właściwości
                      przez 30 min.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={IconGum} />
                  </div>
                  <div className="card-inner">
                    <h3>Krok 4 z 4</h3>
                    <p>
                      Należy wyjąć zużytą saszetkę z ust i umieścić w pojemniku
                      w górnej części opakowania.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </PanSlider>
        </div>
        <div className="page-header">
          <h1>
            Różne smaki. <br />
            Różne natężenia nikotyny.
          </h1>
          <p>
            Paleta smaków i stężeń nikotyny dostępna
            <br /> w ofercie Velo
          </p>
        </div>
        <div className="slider-header">
          <h4 className="slider-header__title slider-header__title--gap">
            Velo mini
          </h4>
          <p className="slider-header__desc">
            Dla zaczynających przygodę z saszetkami nikotynowymi
          </p>
        </div>
        <div className="vuse-slider-mid velo-slider-mid">
          <PanSlider
            slideWidth={props.screen.width}
            slideCount={4}
            arrowDirection={true}
            showDotNav={false}
          >
            <ul className="cards">
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={PolarMint} />
                  </div>
                  <div className="card-inner">
                    <h3>Polar Mint</h3>
                    <p>
                      <strong>Velo mini</strong> (18 saszetek 0,5 g)
                    </p>
                    <p className="polar-mint">
                      stężenie nikotyny: <span className="color-min">4 mg</span>
                      <span className="circle-full"></span>
                      <span className="circle-empty"></span>
                      <span className="circle-empty"></span>
                      <span className="circle-empty"></span>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={BerryFrost} />
                  </div>
                  <div className="card-inner">
                    <h3>Berry Frost</h3>
                    <p>
                      <strong>Velo mini</strong> (18 saszetek 0,5 g)
                    </p>
                    <p className="berry-frost">
                      stężenie nikotyny: <span className="color-min">4 mg</span>
                      <span className="circle-full"></span>
                      <span className="circle-full"></span>
                      <span className="circle-empty"></span>
                      <span className="circle-empty"></span>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={PolarMint2} />
                  </div>
                  <div className="card-inner">
                    <h3>Polar Mint</h3>
                    <p>
                      <strong>Velo mini</strong> (18 saszetek 0,5 g)
                    </p>
                    <p className="polar-mint">
                      stężenie nikotyny: <span className="color-min">6 mg</span>
                      <span className="circle-full"></span>
                      <span className="circle-full"></span>
                      <span className="circle-empty"></span>
                      <span className="circle-empty"></span>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={BerryFrost2} />
                  </div>
                  <div className="card-inner">
                    <h3>Berry Frost</h3>
                    <p>
                      <strong>Velo mini</strong> (18 saszetek 0,5 g)
                    </p>
                    <p className="berry-frost">
                      stężenie nikotyny: <span className="color-min">6 mg</span>
                      <span className="circle-full"></span>
                      <span className="circle-full"></span>
                      <span className="circle-empty"></span>
                      <span className="circle-empty"></span>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </PanSlider>
        </div>
        <div className="slider-header">
          <h4 className="slider-header__title">Velo slim</h4>
          <p className="slider-header__desc">
            Dla doświadczonych szukających większej satysfakcji
          </p>
        </div>
        <div className="vuse-slider-mid velo-slider-mid">
          <PanSlider
            slideWidth={props.screen.width}
            slideCount={4}
            arrowDirection={true}
            showDotNav={false}
          >
            <ul className="cards">
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={PolarMint3} />
                  </div>
                  <div className="card-inner">
                    <h3>Polar Mint</h3>
                    <p>
                      <strong>Velo slim</strong> (20 saszetek 0,7 g)
                    </p>
                    <p className="red">
                      stężenie nikotyny:{" "}
                      <span className="color-min">10 mg</span>
                      <span className="circle-full"></span>
                      <span className="circle-full"></span>
                      <span className="circle-full"></span>
                      <span className="circle-empty"></span>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={BerryFrost3} />
                  </div>
                  <div className="card-inner">
                    <h3>Berry Frost</h3>
                    <p>
                      <strong>Velo slim</strong> (20 saszetek 0,7 g)
                    </p>
                    <p className="red">
                      stężenie nikotyny:{" "}
                      <span className="color-min">10 mg</span>
                      <span className="circle-full"></span>
                      <span className="circle-full"></span>
                      <span className="circle-full"></span>
                      <span className="circle-empty"></span>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={IceCool3} />
                  </div>
                  <div className="card-inner">
                    <h3>Ice Cool</h3>
                    <p>
                      <strong>Velo slim</strong> (20 saszetek 0,7 g)
                    </p>
                    <p className="red">
                      stężenie nikotyny:{" "}
                      <span className="color-min">10 mg</span>
                      <span className="circle-full"></span>
                      <span className="circle-full"></span>
                      <span className="circle-full"></span>
                      <span className="circle-empty"></span>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="card">
                  <div className="card-head">
                    <img src={Freeze4} />
                  </div>
                  <div className="card-inner">
                    <h3>Freeze</h3>
                    <p>
                      <strong>Velo slim</strong> (20 saszetek 0,7 g)
                    </p>
                    <p className="red">
                      stężenie nikotyny:{" "}
                      <span className="color-min">10,9 mg</span>
                      <span className="circle-full"></span>
                      <span className="circle-full"></span>
                      <span className="circle-full"></span>
                      <span className="circle-full"></span>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </PanSlider>
        </div>
        <div style={{ backgroundColor: "#fff", paddingTop: "40px" }}>
          <div className="page-header">
            <h1>Twoje korzyści</h1>
            <p>Sprawdź jakie są plusy niesie za sobą Velo</p>
          </div>
          <div className="velo-slider-bot">
            <PanSlider
              slideWidth={props.screen.width}
              slideCount={6}
              arrowDirection={true}
              showDotNav={false}
            >
              <ul className="cards">
                <li>
                  <div className="card">
                    <div className="card-head">
                      <img src={VeloLogo} />
                    </div>
                    <div className="card-inner">
                      <h3>Pełna dyskrecja użytkowania od początku do końca</h3>
                      <p>
                        Saszetki VELO pozostają białe przed, w&nbsp;trakcie
                        i&nbsp;po&nbsp;użytkowaniu.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="card">
                    <div className="card-head">
                      <img src={VeloLogo} />
                    </div>
                    <div className="card-inner">
                      <h3>Bez dymu i popiołu</h3>
                      <p>
                        Dbamy o pełną satysfakcję nikotynową bez wpływu
                        na&nbsp;innych.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="card">
                    <div className="card-head">
                      <img src={VeloLogo} />
                    </div>
                    <div className="card-inner">
                      <h3>Szeroki wybór smaków i stężeń nikotyny</h3>
                      <p>
                        Bogata oferta smaków i stężeń nikotyny VELO umożliwia
                        dopasowanie do&nbsp;potrzeb każdego użytkownika.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="card">
                    <div className="card-head">
                      <img src={VeloLogo} />
                    </div>
                    <div className="card-inner">
                      <h3>Wysoka jakość skladników</h3>
                      <p>
                        Saszetki VELO są produkowane z&nbsp;wysokiej jakości
                        składników.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="card">
                    <div className="card-head">
                      <img src={VeloLogo} />
                    </div>
                    <div className="card-inner">
                      <h3>Kompaktowa i funkcjonalna puszka</h3>
                      <p>
                        Funkcjonalna puszka VELO umożliwia przechowywanie
                        zużytych saszetek w&nbsp;górnym wieczku.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="card">
                    <div className="card-head">
                      <img src={VeloLogo} />
                    </div>
                    <div className="card-inner">
                      <h3>W pełni recyklingowalne opakowanie</h3>
                      <p>
                        Z myślą o środowisku naturalnym, puszki VELO wykonaliśmy
                        z&nbsp;plastiku, który podlega recyklingowi.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </PanSlider>
          </div>
        </div>
        <div className="page-footer">
          <h2>Rozwiąż quiz i wygraj 5 zł</h2>
          <NavLink className="btn" to="/aktywacje/quiz-season-2">
            Przejdź do quizu
          </NavLink>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state;
  return { screen, user };
};

export default withRouter(
  connect(mapStateToProps, {
    register,
  })(HyperVusePage)
);
