import React from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import { ReactComponent as IconDropDown } from '../../../img/dropdown.svg';
import { ReactComponent as IconClose } from '../../../img/icon-close.svg';
import moment from 'moment';

class Stats extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {  welcomeMessage: true, welcomeStep: 0, season: 0 };
    //this._bind('openActionPopup', 'hideActionPopup');

    this.ranks = [
      'Nowicjusz',
      'Początkujący',
      'Profesjonalista',
      'Top Class',
      'Ultimate',
      'Hero'
    ]
  }

  handleToggleDropDown(){
    this.setState(prevState => ({
      dropDownActive: !prevState.dropDownActive
    }));
  }

  handleSeasonName(season){
      switch (season) {
        case 0:
           return '2022 - Sezon I'
            break;
        case 1:
          return  '2021 - Sezon II'
            break;
        case 2:
          return '2021 - Sezon I'
          break;
        default:
          return    'no case'
    }
  }


  getActivationName(id, solution) {
    let name="";
    let ident = id;
    if (/super_seria/.test(ident)) {
      ident = 'super_seria'
    }
    switch(ident) {
      case "device_register":
        let deviceName = solution.payload.model;
        name = "Rejestracja urządzenia - "+deviceName;
        break;
      case "device_register_bonus":
        name = "Rejestracja urządzenia - Bonus";
        break;
      case "quiz_start":
        name = "Rozwiązanie quizu";
        break;
      case "fill_profile":
        name = "Uzupełniony Profil";
        break;
      case "super_seria_s1":
        name = "Super Seria";
        break;
      case "super_seria_s2":
        name = "Super Seria";
        break;
      case "super_seria_s3":
        name = "Super Seria";
        break;
      case "super_seria_s4":
        name = "Super Seria";
        break; 
      case "super_seria":
        name = "Super Seria";
        break; 
      case "glo_boost":
        name = "Happy Days";
        break; 
      case "quiz_velo":
        name = "Quiz Velo";
        break;
      case "quiz":
        name = "Quiz";
        break;
      case "glo_boost_2":
        name = "Glo Boost";
        break;
      case "consumer_register":
        name = "Rejestracja Konsumenta";
        break;
        case "super_seria_lvl1_q1_22":
        case "super_seria_lvl2_q1_22":
        case "super_seria_lvl5_q1_22":
        case "super_seria_lvl1_q2_22":
        case "super_seria_lvl5_q4_22":
          name = "Super Seria";
          break; 
      default:
        name = '';
        break;
    }

    return name;
  }

  render() {
    return (
      <div className="page stats">
        <section>
          <div className="page-header">
            <h1>Moje statystyki</h1>
          </div>
          <div className="stats-summary">
            <div className="stats-header">
              <h3>Sprzedane urządzenia</h3>
              <div>Razem: <span className="score">{ this.props.account.seasons[this.state.season].soldDevices.all }</span></div>
            </div>
            <div className="stats-pane">
              <div className="stats-pane-header">
                <ul className="seasons">
                <div className="drop-down">
                <div onClick={() => this.handleToggleDropDown()} className="drop-down-active">{this.handleSeasonName(this.state.season)} 
                <span className={this.state.dropDownActive ? 'arrow arrow-active' : 'arrow'}><IconDropDown/></span></div>
                <ul className={this.state.dropDownActive ? "drop-down-list drop-down-list--active" : "drop-down-list"}>
                  <li onClick={() => this.setState({season:0},this.handleToggleDropDown())} className="drop-down-list__item">2022 - Sezon I</li>
                  <li onClick={() => this.setState({season:1},this.handleToggleDropDown())} className="drop-down-list__item">2021 - Sezon II</li>
                  <li onClick={() => this.setState({season:2},this.handleToggleDropDown())} className="drop-down-list__item">2021 - Sezon I</li>
                </ul>
              </div>
                  {/* <li className={this.state.season === 0 ? "active" : ""} onClick={()=>{this.setState({season: 0})}}>Sezon II</li>
                  {<li className={this.state.season === 1 ? "active" : ""} onClick={()=>{this.setState({season: 1})}}>Sezon I</li>} */}
                  {/*<li>Jesień</li>
                  <li>Zima</li>*/}
                </ul>
                <div className="sold-products">
                  <div className="item-stats">
                    <div className="hyper-line" style={{ width: (this.props.account.seasons[this.state.season].soldDevices.hyper / this.props.account.seasons[this.state.season].soldDevices.all)*100 - 1+'%' }}></div>
                    {/*<div className="vuse-line" style={{ width: (this.props.account.seasons[this.state.season].soldDevices.vuse / this.props.account.seasons[this.state.season].soldDevices.all)*100 - 1+'%' }}></div>*/}
                  </div>
                  <div className="sold-items">
                    <div className="item item-hyper">
                      <div>glo Hyper</div>
                      <div className="score">{ this.props.account.seasons[this.state.season].soldDevices.hyper }</div>
                    </div>
                    {/*<div className="item item-vuse">
                      <div>Vuse ePen</div>
                      <div className="score">{ this.props.account.seasons[this.state.season].soldDevices.vuse }</div>
                </div>*/}
                  </div>
                </div>
              </div>
              <div className="ranks-pane">
                <h4>Rangi</h4>
                { this.props.account.seasons[this.state.season].rankNext != 'zero' ? <p>Brakuje Ci tylko <strong>{ this.props.account.seasons[this.state.season].rankNext }</strong> { this.props.account.seasons[this.state.season].rankNext === "jednego" ? "sprzedanego urządzenia" : "sprzedanych urządzeń" } do awansu!</p> : '' }
                <div className="ranks-holder">
                  <ul className="ranks">
                    { this.ranks.map((item, key)=>{
                        let cl = '';
                        if ( key === this.props.account.seasons[this.state.season].rankId) {
                          cl = 'current';
                        } else if ( this.props.account.seasons[this.state.season].rankId > key ) {
                          cl = 'completed';
                        }

                        return <li key={ key }  className={cl}><span>{ item }</span></li>
                      })
                    }
                  </ul>
                </div>
                {/*<p className="disclaimer">Osiągnięcie rangi Ultimate poza bonusem powoli Ci w następnym sezonie zgarniać <strong>dodatkowe 5 zł</strong> wyższe nagrody za każdą sprzedaż.</p>*/}
              </div>
            </div>
          </div>
        </section>
        <section className="history-section">
          <h3>Historia wpływów</h3>
          <table>
            <tbody>
            { this.props.account.incomeHistory.map((item, key)=> {
                return (
                  <tr key={ key }>
                    <td className="date">{ moment(item.created.date).format('DD.MM.YYYY')}</td>
                    <td className="name">{ this.getActivationName(item.solution.activation.ident, item.solution) }</td>
                    <td className="score">{ item.value+' zł'}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, user, account } = state
  return { screen, user, account }
};

export default withRouter(connect(
  mapStateToProps,
  {
    
  }
)(Stats));