import React, { Component } from 'react';
import Hammer from 'react-hammerjs'

class PanSlider extends Component {

    constructor(props) {
        super(props)
        this.state = { activeSlide: 0, panOffset: 0, transition: false }
        this._bind('handlePan')
    }

    _bind(...methods) {
        methods.forEach((method) => this[method] = this[method].bind(this));
    }

    slideTo(id) {
        this.setState({ index: id })
    }

    handlePan(e) {
        /*if (e.eventType != 8) {*/
        const activeSlide = this.state.activeSlide
        const percentage = 100 / this.props.slideCount * e.deltaX / this.props.slideWidth;
        const transformPercentage = percentage - 100 / this.props.slideCount * activeSlide;
        this.setState({ transition: false, panOffset: transformPercentage })
        if (e.isFinal) {
            if (percentage < 0 && activeSlide < this.props.slideCount - 1 && e.overallVelocityY > -1 && e.deltaX < -50)
                this.setState({ transition: true, activeSlide: activeSlide + 1, panOffset: -(activeSlide + 1) * (100 / this.props.slideCount) })
            else if (percentage > 0 && activeSlide > 0 && e.overallVelocityY > -1 && e.deltaX > 50)
                this.setState({ transition: true, activeSlide: activeSlide - 1, panOffset: -(activeSlide - 1) * (100 / this.props.slideCount) })
            else
                this.setState({ transition: true, panOffset: -activeSlide * (100 / this.props.slideCount) })
        }
        /*}*/
    }

    render() {
        let dotNav = []
        for (let i = 0; i < this.props.slideCount; i++) {
            let item = <li key={i} className={this.state.activeSlide === i ? "active" : ""} onClick={() => { this.setState({ transition: true, activeSlide: i, panOffset: -(i) * (100 / this.props.slideCount) }) }}></li>
            dotNav.push(item)
        }
        return (
            <div className="slider-holder">
                <Hammer onPan={ this.handlePan } direction="DIRECTION_HORIZONTAL">
                    <div className={this.state.transition ? "slider transition" : "slider"} style={{ width: this.props.slideCount * this.props.slideWidth, transform: 'translate(' + (this.state.panOffset) + '%,0)' }}>
                        {this.props.children}
                    </div>
                </Hammer>
                { this.props.showDotNav && dotNav.length > 1 ?
                    <ul className="dot-nav">
                        {dotNav}
                    </ul> : null}
                { this.props.showSliderCounter ?
                    <div className="slider-counter">{(this.state.activeSlide + 1) + ' / ' + (this.props.slideCount)}</div> : null}
            </div>
        );
    }
}

export default PanSlider;