import React from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import BaseComponent from "../Helpers";
import { otp, updateUser } from '../../actions/user';
import moment from 'moment';

class Profile extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {  
      welcomeMessage: true, 
      welcomeStep: 0,
      firstName: this.props.user.user.firstName,
      lastName: this.props.user.user.lastName,
      mobilePhone: this.props.user.user.mobilePhone,
      salesPoint: '',
      dob: this.props.user.user.dob || "",
      streetAddress: this.props.user.user.streetAddress || "",
      postalCode: this.props.user.user.postalCode || "",
      cityName: this.props.user.user.cityName || "",
      taxNumber: this.props.user.user.taxNumber || "",
      taxOfficeName: this.props.user.user.taxOfficeName || "",
      foreignTaxResidence: this.props.user.user.foreignTaxResidence || "",
      businessOwnership: this.props.user.user.businessOwnership || false,
      fields: {},
      otp: '',
      residency: true,
      jdg: false,
      error: ''
    };

    this.us = [
      {
        "name": "Urząd Skarbowy w Bolesławcu"
      },
      {
        "name": "Urząd Skarbowy w Bystrzycy Kłodzkiej"
      },
      {
        "name": "Urząd Skarbowy w  Dzierżoniowie"
      },
      {
        "name": "Urząd Skarbowy w Głogowie"
      },
      {
        "name": "Urząd Skarbowy w Jaworze"
      },
      {
        "name": "Urząd Skarbowy w Jeleniej Górze"
      },
      {
        "name": "Urząd Skarbowy w Kamiennej Górze"
      },
      {
        "name": "Urząd Skarbowy w  Kłodzku"
      },
      {
        "name": "Urząd Skarbowy w Legnicy"
      },
      {
        "name": "Urząd Skarbowy w Lubaniu"
      },
      {
        "name": "Urząd Skarbowy w Lubinie"
      },
      {
        "name": "Urząd Skarbowy w Lwówku Śląskim"
      },
      {
        "name": "Urząd Skarbowy w Miliczu"
      },
      {
        "name": "Urząd Skarbowy w  Nowej Rudzie"
      },
      {
        "name": "Urząd Skarbowy w Oleśnicy"
      },
      {
        "name": "Urząd Skarbowy w Oławie"
      },
      {
        "name": "Urząd Skarbowy w Strzelinie"
      },
      {
        "name": "Urząd Skarbowy w Środzie Śląskiej"
      },
      {
        "name": "Urząd Skarbowy w Świdnicy"
      },
      {
        "name": "Urząd Skarbowy w Trzebnicy"
      },
      {
        "name": "Urząd Skarbowy w  Wałbrzychu"
      },
      {
        "name": "Urząd Skarbowy w Wołowie"
      },
      {
        "name": "Urząd Skarbowy Wrocław - Fabryczna"
      },
      {
        "name": "Urząd Skarbowy Wrocław - Krzyki"
      },
      {
        "name": "Urząd Skarbowy Wrocław - Psie Pole"
      },
      {
        "name": "Urząd Skarbowy Wrocław - Stare Miasto"
      },
      {
        "name": "Urząd Skarbowy Wrocław - Śródmieście"
      },
      {
        "name": "Pierwszy Urząd Skarbowy we Wrocławiu"
      },
      {
        "name": "Urząd Skarbowy w Ząbkowicach Śląskich"
      },
      {
        "name": "Urząd Skarbowy w Zgorzelcu"
      },
      {
        "name": "Urząd Skarbowy w Złotoryi"
      },
      {
        "name": "Urząd skarbowy w Górze"
      },
      {
        "name": "Urząd Skarbowy w Polkowicach"
      },
      {
        "name": "Dolnośląski Urząd Skarbowy we Wrocławiu"
      },
      {
        "name": "Urząd Skarbowy w Aleksandrowie Kujawskim"
      },
      {
        "name": "Urząd Skarbowy w Brodnicy"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Bydgoszczy"
      },
      {
        "name": "Drugi Urząd Skarbowy w Bydgoszczy"
      },
      {
        "name": "Trzeci Urząd Skarbowy w Bydgoszczy"
      },
      {
        "name": "Urząd Skarbowy w Chełmnie"
      },
      {
        "name": "Urząd Skarbowy w Grudziądzu"
      },
      {
        "name": "Urząd Skarbowy w Inowrocławiu"
      },
      {
        "name": "Urząd Skarbowy w Lipnie"
      },
      {
        "name": "Urząd Skarbowy w Mogilnie"
      },
      {
        "name": "Urząd Skarbowy w Nakle nad Notecią"
      },
      {
        "name": "Urząd Skarbowy w Radziejowie"
      },
      {
        "name": "Urząd Skarbowy w Rypinie"
      },
      {
        "name": "Urząd Skarbowy w Świeciu"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Toruniu"
      },
      {
        "name": "Drugi Urząd Skarbowy w Toruniu"
      },
      {
        "name": "Urząd Skarbowy w Tucholi"
      },
      {
        "name": "Urząd Skarbowy w Wąbrzeźnie"
      },
      {
        "name": "Urząd Skarbowy we Włocławku"
      },
      {
        "name": "Urząd Skarbowy w Żninie"
      },
      {
        "name": "Urząd Skarbowy w Golubiu-Dobrzyniu"
      },
      {
        "name": "Urząd Skarbowy w Sępólnie Krajeńskim"
      },
      {
        "name": "Kujawsko-Pomorski Urząd Skarbowy w Bydgoszczy"
      },
      {
        "name": "Urząd Skarbowy w Białej Podlaskiej"
      },
      {
        "name": "Urząd Skarbowy w Biłgoraju"
      },
      {
        "name": "Urząd Skarbowy w Chełmie"
      },
      {
        "name": "Urząd Skarbowy w Hrubieszowie"
      },
      {
        "name": "Urząd Skarbowy w Janowie Lubelskim"
      },
      {
        "name": "Urząd Skarbowy w Krasnymstawie"
      },
      {
        "name": "Urząd Skarbowy w Kraśniku"
      },
      {
        "name": "Urząd Skarbowy w Lubartowie"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Lublinie"
      },
      {
        "name": "Drugi Urząd Skarbowy w Lublinie"
      },
      {
        "name": "Trzeci Urząd Skarbowy w Lublinie"
      },
      {
        "name": "Urząd Skarbowy w Łukowie"
      },
      {
        "name": "Urząd Skarbowy w Opolu Lubelskim"
      },
      {
        "name": "Urząd Skarbowy w Parczewie"
      },
      {
        "name": "Urząd Skarbowy w Puławach"
      },
      {
        "name": "Urząd Skarbowy w Radzyniu Podlaskim"
      },
      {
        "name": "Urząd Skarbowy w Tomaszowie Lubelskim"
      },
      {
        "name": "Urząd Skarbowy we Włodawie"
      },
      {
        "name": "Urząd Skarbowy w Zamościu"
      },
      {
        "name": "Urząd Skarbowy w Łęcznej"
      },
      {
        "name": "Urząd Skarbowy w Rykach"
      },
      {
        "name": "Lubelski Urząd Skarbowy w Lublinie"
      },
      {
        "name": "Urząd Skarbowy w Gorzowie Wielkopolskim"
      },
      {
        "name": "Urząd Skarbowy w Krośnie Odrzańskim"
      },
      {
        "name": "Urząd Skarbowy w Międzyrzeczu"
      },
      {
        "name": "Urząd Skarbowy w Nowej Soli"
      },
      {
        "name": "Urząd Skarbowy w Słubicach"
      },
      {
        "name": "Urząd Skarbowy w Świebodzinie"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Zielonej Górze"
      },
      {
        "name": "Drugi Urząd Skarbowy w Zielonej Górze"
      },
      {
        "name": "Urząd Skarbowy w Żaganiu"
      },
      {
        "name": "Urząd Skarbowy w Żarach"
      },
      {
        "name": "Urząd Skarbowy w Drezdenku"
      },
      {
        "name": "Urząd Skarbowy w Sulęcinie"
      },
      {
        "name": "Urząd Skarbowy we Wschowie"
      },
      {
        "name": "Lubuski Urząd Skarbowy w Zielonej Górze"
      },
      {
        "name": "Urząd Skarbowy w Bełchatowie"
      },
      {
        "name": "Urząd Skarbowy w Brzezinach"
      },
      {
        "name": "Urząd Skarbowy w Głownie"
      },
      {
        "name": "Urząd Skarbowy w Kutnie"
      },
      {
        "name": "Urząd Skarbowy w Łasku"
      },
      {
        "name": "Urząd Skarbowy w Łowiczu"
      },
      {
        "name": "Pierwszy Urząd Skarbowy Łódź - Bałuty"
      },
      {
        "name": "Drugi Urząd Skarbowy Łódź - Bałuty"
      },
      {
        "name": "Pierwszy Urząd Skarbowy Łódź - Górna"
      },
      {
        "name": "Drugi Urząd Skarbowy Łódź - Górna"
      },
      {
        "name": "Urząd Skarbowy Łódź - Polesie"
      },
      {
        "name": "Urząd Skarbowy Łódź - Śródmieście"
      },
      {
        "name": "Urząd Skarbowy Łódź - Widzew"
      },
      {
        "name": "Urząd Skarbowy w Opocznie"
      },
      {
        "name": "Urząd Skarbowy w Pabianicach"
      },
      {
        "name": "Urząd Skarbowy w Piotrkowie Trybunalskim"
      },
      {
        "name": "Urząd Skarbowy w Poddębicach"
      },
      {
        "name": "Urząd Skarbowy w Radomsku"
      },
      {
        "name": "Urząd Skarbowy w Rawie Mazowieckiej"
      },
      {
        "name": "Urząd Skarbowy w Sieradzu"
      },
      {
        "name": "Urząd Skarbowy w Skierniewicach"
      },
      {
        "name": "Urząd Skarbowy w Tomaszowie Mazowieckim"
      },
      {
        "name": "Urząd Skarbowy w Wieluniu"
      },
      {
        "name": "Urząd Skarbowy w Zduńskiej Woli"
      },
      {
        "name": "Urząd Skarbowy w Zgierzu"
      },
      {
        "name": "Urząd Skarbowy w Wieruszowie"
      },
      {
        "name": "Urząd Skarbowy w Łęczycy"
      },
      {
        "name": "Urząd Skarbowy w Pajęcznie"
      },
      {
        "name": "Łódzki Urząd Skarbowy w Łodzi"
      },
      {
        "name": "Urząd Skarbowy w Bochni"
      },
      {
        "name": "Urząd Skarbowy w Brzesku"
      },
      {
        "name": "Urząd Skarbowy w Chrzanowie"
      },
      {
        "name": "Urząd Skarbowy w Dąbrowie Tarnowskiej"
      },
      {
        "name": "Urząd Skarbowy w Gorlicach"
      },
      {
        "name": "Pierwszy Urząd Skarbowy Kraków"
      },
      {
        "name": "Urząd Skarbowy Kraków - Krowodrza"
      },
      {
        "name": "Urząd Skarbowy Kraków - Nowa Huta"
      },
      {
        "name": "Urząd Skarbowy Kraków - Podgórze"
      },
      {
        "name": "Urząd Skarbowy Kraków - Prądnik"
      },
      {
        "name": "Urząd Skarbowy Kraków - Stare Miasto"
      },
      {
        "name": "Urząd Skarbowy Kraków - Śródmieście"
      },
      {
        "name": "Urząd Skarbowy w Limanowej"
      },
      {
        "name": "Urząd Skarbowy w Miechowie"
      },
      {
        "name": "Urząd Skarbowy w Myślenicach"
      },
      {
        "name": "Urząd Skarbowy w Nowym Sączu"
      },
      {
        "name": "Urząd Skarbowy w Nowym Targu"
      },
      {
        "name": "Urząd Skarbowy w Olkuszu"
      },
      {
        "name": "Urząd Skarbowy w Oświęcimiu"
      },
      {
        "name": "Urząd Skarbowy w Proszowicach"
      },
      {
        "name": "Urząd Skarbowy w Suchej Beskidzkiej"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Tarnowie"
      },
      {
        "name": "Drugi Urząd Skarbowy w Tarnowie"
      },
      {
        "name": "Urząd Skarbowy w Wadowicach"
      },
      {
        "name": "Urząd Skarbowy w Wieliczce"
      },
      {
        "name": "Urząd Skarbowy w Zakopanem"
      },
      {
        "name": "Drugi Urząd Skarbowy Kraków"
      },
      {
        "name": "Małopolski Urząd Skarbowy w Krakowie"
      },
      {
        "name": "Urząd Skarbowy w Białobrzegach"
      },
      {
        "name": "Urząd Skarbowy w Ciechanowie"
      },
      {
        "name": "Urząd Skarbowy w Garwolinie"
      },
      {
        "name": "Urząd Skarbowy w Gostyninie"
      },
      {
        "name": "Urząd Skarbowy w Grodzisku Mazowieckim"
      },
      {
        "name": "Urząd Skarbowy w Grójcu"
      },
      {
        "name": "Urząd Skarbowy w Kozienicach"
      },
      {
        "name": "Urząd Skarbowy w Legionowie"
      },
      {
        "name": "Urząd Skarbowy w Łosicach"
      },
      {
        "name": "Urząd Skarbowy w Makowie Mazowieckim"
      },
      {
        "name": "Urząd Skarbowy w Mińsku Mazowieckim"
      },
      {
        "name": "Urząd Skarbowy w Mławie"
      },
      {
        "name": "Urząd Skarbowy w Nowym Dworze Mazowieckim"
      },
      {
        "name": "Urząd Skarbowy w Ostrołęce"
      },
      {
        "name": "Urząd Skarbowy w Ostrowi Mazowieckiej"
      },
      {
        "name": "Urząd Skarbowy w Otwocku"
      },
      {
        "name": "Urząd Skarbowy w Piasecznie"
      },
      {
        "name": "Urząd Skarbowy w Płocku"
      },
      {
        "name": "Urząd Skarbowy w Płońsku"
      },
      {
        "name": "Urząd Skarbowy w Pruszkowie"
      },
      {
        "name": "Urząd Skarbowy w Przasnyszu"
      },
      {
        "name": "Urząd Skarbowy w Pułtusku"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Radomiu"
      },
      {
        "name": "Drugi Urząd Skarbowy w Radomiu"
      },
      {
        "name": "Urząd Skarbowy w Siedlcach"
      },
      {
        "name": "Urząd Skarbowy w Sierpcu"
      },
      {
        "name": "Urząd Skarbowy w Sochaczewie"
      },
      {
        "name": "Urząd Skarbowy w Sokołowie Podlaskim"
      },
      {
        "name": "Urząd Skarbowy w Szydłowcu"
      },
      {
        "name": "Urząd Skarbowy Warszawa - Bemowo"
      },
      {
        "name": "Urząd Skarbowy Warszawa - Bielany"
      },
      {
        "name": "Urząd Skarbowy Warszawa - Mokotów"
      },
      {
        "name": "Urząd Skarbowy Warszawa - Praga"
      },
      {
        "name": "Pierwszy Urząd Skarbowy Warszawa - Śródmieście"
      },
      {
        "name": "Drugi Urząd Skarbowy Warszawa - Śródmieście"
      },
      {
        "name": "Urząd Skarbowy Warszawa - Targówek"
      },
      {
        "name": "Urząd Skarbowy Warszawa - Ursynów"
      },
      {
        "name": "Urząd Skarbowy Warszawa - Wawer"
      },
      {
        "name": "Urząd Skarbowy Warszawa - Wola"
      },
      {
        "name": "Urząd Skarbowy w Węgrowie"
      },
      {
        "name": "Urząd Skarbowy w Wołominie"
      },
      {
        "name": "Urząd Skarbowy w Wyszkowie"
      },
      {
        "name": "Urząd Skarbowy w Zwoleniu"
      },
      {
        "name": "Urząd Skarbowy w Żurominie"
      },
      {
        "name": "Urząd Skarbowy w Żyrardowie"
      },
      {
        "name": "Urząd Skarbowy w Lipsku"
      },
      {
        "name": "Urząd Skarbowy w Przysusze"
      },
      {
        "name": "Trzeci Urząd Skarbowy Warszawa - Śródmieście"
      },
      {
        "name": "Pierwszy Mazowiecki Urząd Skarbowy w Warszawie"
      },
      {
        "name": "Drugi Mazowiecki Urząd Skarbowy w Warszawie"
      },
      {
        "name": "Trzeci Mazowiecki Urząd Skarbowy w Radomiu"
      },
      {
        "name": "Urząd Skarbowy w Brzegu"
      },
      {
        "name": "Urząd Skarbowy w Głubczycach"
      },
      {
        "name": "Urząd Skarbowy w Kędzierzynie-Koźlu"
      },
      {
        "name": "Urząd Skarbowy w Kluczborku"
      },
      {
        "name": "Urząd Skarbowy w Namysłowie"
      },
      {
        "name": "Urząd Skarbowy w Nysie"
      },
      {
        "name": "Urząd Skarbowy w Oleśnie"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Opolu"
      },
      {
        "name": "Drugi Urząd Skarbowy w Opolu"
      },
      {
        "name": "Urząd Skarbowy w Prudniku"
      },
      {
        "name": "Urząd Skarbowy w Strzelcach Opolskich"
      },
      {
        "name": "Urząd Skarbowy w Krapkowicach"
      },
      {
        "name": "Opolski Urząd Skarbowy w Opolu"
      },
      {
        "name": "Urząd Skarbowy w Brzozowie"
      },
      {
        "name": "Urząd Skarbowy w Dębicy"
      },
      {
        "name": "Urząd Skarbowy w Jarosławiu"
      },
      {
        "name": "Urząd Skarbowy w Jaśle"
      },
      {
        "name": "Urząd Skarbowy w Kolbuszowej"
      },
      {
        "name": "Urząd Skarbowy w Krośnie"
      },
      {
        "name": "Urząd Skarbowy w Lesku"
      },
      {
        "name": "Urząd Skarbowy w Leżajsku"
      },
      {
        "name": "Urząd Skarbowy w Lubaczowie"
      },
      {
        "name": "Urząd Skarbowy w Łańcucie"
      },
      {
        "name": "Urząd Skarbowy w Mielcu"
      },
      {
        "name": "Urząd Skarbowy w Przemyślu"
      },
      {
        "name": "Urząd Skarbowy w Przeworsku"
      },
      {
        "name": "Urząd Skarbowy w Ropczycach"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Rzeszowie"
      },
      {
        "name": "Urząd Skarbowy w Sanoku"
      },
      {
        "name": "Urząd Skarbowy w Stalowej Woli"
      },
      {
        "name": "Urząd Skarbowy w Strzyżowie"
      },
      {
        "name": "Urząd Skarbowy w Tarnobrzegu"
      },
      {
        "name": "Urząd Skarbowy w Ustrzykach Dolnych"
      },
      {
        "name": "Drugi Urząd Skarbowy w Rzeszowie"
      },
      {
        "name": "Urząd Skarbowy w Nisku"
      },
      {
        "name": "Podkarpacki Urząd Skarbowy w Rzeszowie"
      },
      {
        "name": "Urząd Skarbowy w Augustowie"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Białymstoku"
      },
      {
        "name": "Drugi Urząd Skarbowy w Białymstoku"
      },
      {
        "name": "Urząd Skarbowy w Bielsku Podlaskim"
      },
      {
        "name": "Urząd Skarbowy w Grajewie"
      },
      {
        "name": "Urząd Skarbowy w Kolnie"
      },
      {
        "name": "Urząd Skarbowy w Łomży"
      },
      {
        "name": "Urząd Skarbowy w Mońkach"
      },
      {
        "name": "Urząd Skarbowy w Siemiatyczach"
      },
      {
        "name": "Urząd Skarbowy w Sokółce"
      },
      {
        "name": "Urząd Skarbowy w Suwałkach"
      },
      {
        "name": "Urząd Skarbowy w Wysokiem Mazowieckiem"
      },
      {
        "name": "Urząd Skarbowy w Zambrowie"
      },
      {
        "name": "Urząd Skarbowy w Hajnówce"
      },
      {
        "name": "Podlaski Urząd Skarbowy w Białymstoku"
      },
      {
        "name": "Urząd Skarbowy w Bytowie"
      },
      {
        "name": "Urząd Skarbowy w Chojnicach"
      },
      {
        "name": "Urząd Skarbowy w Człuchowie"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Gdańsku"
      },
      {
        "name": "Drugi Urząd Skarbowy w Gdańsku"
      },
      {
        "name": "Trzeci Urząd Skarbowy w Gdańsku"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Gdyni"
      },
      {
        "name": "Drugi Urząd Skarbowy w Gdyni"
      },
      {
        "name": "Urząd Skarbowy w Kartuzach"
      },
      {
        "name": "Urząd Skarbowy w Kościerzynie"
      },
      {
        "name": "Urząd Skarbowy w Kwidzynie"
      },
      {
        "name": "Urząd Skarbowy w Lęborku"
      },
      {
        "name": "Urząd Skarbowy w Malborku"
      },
      {
        "name": "Urząd Skarbowy w Pucku"
      },
      {
        "name": "Urząd Skarbowy w Słupsku"
      },
      {
        "name": "Urząd Skarbowy w Sopocie"
      },
      {
        "name": "Urząd Skarbowy w Starogardzie Gdańskim"
      },
      {
        "name": "Urząd Skarbowy w Tczewie"
      },
      {
        "name": "Urząd Skarbowy w Wejherowie"
      },
      {
        "name": "Urząd Skarbowy w Pruszczu Gdańskim"
      },
      {
        "name": "Pomorski Urząd Skarbowy w Gdańsku"
      },
      {
        "name": "Urząd Skarbowy w Będzinie"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Bielsku-Białej"
      },
      {
        "name": "Drugi Urząd Skarbowy w Bielsku-Białej"
      },
      {
        "name": "Urząd Skarbowy w Bytomiu"
      },
      {
        "name": "Urząd Skarbowy w Chorzowie"
      },
      {
        "name": "Urząd Skarbowy w Cieszynie"
      },
      {
        "name": "Urząd Skarbowy w Czechowicach-Dziedzicach"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Częstochowie"
      },
      {
        "name": "Drugi Urząd Skarbowy w Częstochowie"
      },
      {
        "name": "Urząd Skarbowy w Dąbrowie Górniczej"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Gliwicach"
      },
      {
        "name": "Drugi Urząd Skarbowy w Gliwicach"
      },
      {
        "name": "Urząd Skarbowy w Jastrzębiu-Zdroju"
      },
      {
        "name": "Urząd Skarbowy w Jaworznie"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Katowicach"
      },
      {
        "name": "Drugi Urząd Skarbowy w Katowicach"
      },
      {
        "name": "Urząd Skarbowy w Kłobucku"
      },
      {
        "name": "Urząd Skarbowy w Lublińcu"
      },
      {
        "name": "Urząd Skarbowy w Mikołowie"
      },
      {
        "name": "Urząd Skarbowy w Mysłowicach"
      },
      {
        "name": "Urząd Skarbowy w Myszkowie"
      },
      {
        "name": "Urząd Skarbowy w Piekarach Śląskich"
      },
      {
        "name": "Urząd Skarbowy w Pszczynie"
      },
      {
        "name": "Urząd Skarbowy w Raciborzu"
      },
      {
        "name": "Urząd Skarbowy w Rudzie Śląskiej"
      },
      {
        "name": "Urząd Skarbowy w Rybniku"
      },
      {
        "name": "Urząd Skarbowy w Siemianowicach Śląskich"
      },
      {
        "name": "Urząd Skarbowy w Sosnowcu"
      },
      {
        "name": "Urząd Skarbowy w Tarnowskich Górach"
      },
      {
        "name": "Urząd Skarbowy w Tychach"
      },
      {
        "name": "Urząd Skarbowy w Wodzisławiu Śląskim"
      },
      {
        "name": "Urząd Skarbowy w Zabrzu"
      },
      {
        "name": "Urząd Skarbowy w Zawierciu"
      },
      {
        "name": "Urząd Skarbowy w Żorach"
      },
      {
        "name": "Urząd Skarbowy w Żywcu"
      },
      {
        "name": "Pierwszy Śląski Urząd Skarbowy w Sosnowcu"
      },
      {
        "name": "Drugi Śląski Urząd Skarbowy \nw Bielsku-Białej"
      },
      {
        "name": "Urząd Skarbowy w Busku-Zdroju"
      },
      {
        "name": "Urząd Skarbowy w Jędrzejowie"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Kielcach"
      },
      {
        "name": "Drugi Urząd Skarbowy w Kielcach"
      },
      {
        "name": "Urząd Skarbowy w Końskich"
      },
      {
        "name": "Urząd Skarbowy w Opatowie"
      },
      {
        "name": "Urząd Skarbowy w Ostrowcu Świętokrzyskim"
      },
      {
        "name": "Urząd Skarbowy w Pińczowie"
      },
      {
        "name": "Urząd Skarbowy w Sandomierzu"
      },
      {
        "name": "Urząd Skarbowy w Skarżysku-Kamiennej"
      },
      {
        "name": "Urząd Skarbowy w Starachowicach"
      },
      {
        "name": "Urząd Skarbowy w Staszowie"
      },
      {
        "name": "Urząd Skarbowy w Kazimierzy Wielkiej"
      },
      {
        "name": "Urząd Skarbowy we Włoszczowie"
      },
      {
        "name": "Świętokrzyski Urząd Skarbowy w Kielcach"
      },
      {
        "name": "Urząd Skarbowy w Bartoszycach"
      },
      {
        "name": "Urząd Skarbowy w Braniewie"
      },
      {
        "name": "Urząd Skarbowy w Działdowie"
      },
      {
        "name": "Urząd Skarbowy w Elblągu"
      },
      {
        "name": "Urząd Skarbowy w Ełku"
      },
      {
        "name": "Urząd Skarbowy w Giżycku"
      },
      {
        "name": "Urząd Skarbowy w Iławie"
      },
      {
        "name": "Urząd Skarbowy w Kętrzynie"
      },
      {
        "name": "Urząd Skarbowy w Nidzicy"
      },
      {
        "name": "Urząd Skarbowy w Nowym Mieście Lubawskim"
      },
      {
        "name": "Urząd Skarbowy w Olecku"
      },
      {
        "name": "Urząd Skarbowy w Olsztynie"
      },
      {
        "name": "Urząd Skarbowy w Ostródzie"
      },
      {
        "name": "Urząd Skarbowy w Piszu"
      },
      {
        "name": "Urząd Skarbowy w Szczytnie"
      },
      {
        "name": "Warmińsko-Mazurski Urząd Skarbowy w Olsztynie"
      },
      {
        "name": "Urząd Skarbowy w Czarnkowie"
      },
      {
        "name": "Urząd Skarbowy w Gnieźnie"
      },
      {
        "name": "Urząd Skarbowy w Gostyniu"
      },
      {
        "name": "Urząd Skarbowy w Grodzisku Wielkopolskim"
      },
      {
        "name": "Urząd Skarbowy w Jarocinie"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Kaliszu"
      },
      {
        "name": "Drugi Urząd Skarbowy w Kaliszu"
      },
      {
        "name": "Urząd Skarbowy w Kępnie"
      },
      {
        "name": "Urząd Skarbowy w Kole"
      },
      {
        "name": "Urząd Skarbowy w Koninie"
      },
      {
        "name": "Urząd Skarbowy w Kościanie"
      },
      {
        "name": "Urząd Skarbowy w Krotoszynie"
      },
      {
        "name": "Urząd Skarbowy w Lesznie"
      },
      {
        "name": "Urząd Skarbowy w Międzychodzie"
      },
      {
        "name": "Urząd Skarbowy w Nowym Tomyślu"
      },
      {
        "name": "Urząd Skarbowy w Ostrowie Wielkopolskim"
      },
      {
        "name": "Urząd Skarbowy w Ostrzeszowie"
      },
      {
        "name": "Urząd Skarbowy w Pile"
      },
      {
        "name": "Urząd Skarbowy Poznań - Grunwald"
      },
      {
        "name": "Urząd Skarbowy Poznań - Jeżyce"
      },
      {
        "name": "Urząd Skarbowy Poznań - Nowe Miasto"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Poznaniu"
      },
      {
        "name": "Urząd Skarbowy Poznań - Winogrady"
      },
      {
        "name": "Urząd Skarbowy Poznań - Wilda"
      },
      {
        "name": "Urząd Skarbowy w Rawiczu"
      },
      {
        "name": "Urząd Skarbowy w Słupcy"
      },
      {
        "name": "Urząd Skarbowy w Szamotułach"
      },
      {
        "name": "Urząd Skarbowy w Śremie"
      },
      {
        "name": "Urząd Skarbowy w Środzie Wielkopolskiej"
      },
      {
        "name": "Urząd Skarbowy w Turku"
      },
      {
        "name": "Urząd Skarbowy w Wągrowcu"
      },
      {
        "name": "Urząd Skarbowy w Wolsztynie"
      },
      {
        "name": "Urząd Skarbowy we Wrześni"
      },
      {
        "name": "Urząd Skarbowy w Złotowie"
      },
      {
        "name": "Urząd Skarbowy w Chodzieży"
      },
      {
        "name": "Urząd Skarbowy w Obornikach"
      },
      {
        "name": "Urząd Skarbowy w Pleszewie"
      },
      {
        "name": "Pierwszy Wielkopolski Urząd Skarbowy w Poznaniu"
      },
      {
        "name": "Drugi Wielkopolski Urząd Skarbowy w Kaliszu"
      },
      {
        "name": "Urząd Skarbowy w Białogardzie"
      },
      {
        "name": "Urząd Skarbowy w Choszcznie"
      },
      {
        "name": "Urząd Skarbowy w Drawsku Pomorskim"
      },
      {
        "name": "Urząd Skarbowy w Goleniowie"
      },
      {
        "name": "Urząd Skarbowy w Gryficach"
      },
      {
        "name": "Urząd Skarbowy w Gryfinie"
      },
      {
        "name": "Urząd Skarbowy w Kamieniu Pomorskim"
      },
      {
        "name": "Urząd Skarbowy w Kołobrzegu"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Koszalinie"
      },
      {
        "name": "Drugi Urząd Skarbowy w Koszalinie"
      },
      {
        "name": "Urząd Skarbowy w Myśliborzu"
      },
      {
        "name": "Urząd Skarbowy w Pyrzycach"
      },
      {
        "name": "Urząd Skarbowy w Stargardzie"
      },
      {
        "name": "Pierwszy Urząd Skarbowy w Szczecinie"
      },
      {
        "name": "Drugi Urząd Skarbowy w Szczecinie"
      },
      {
        "name": "Trzeci Urząd Skarbowy w Szczecinie"
      },
      {
        "name": "Urząd Skarbowy w Szczecinku"
      },
      {
        "name": "Urząd Skarbowy w Świnoujściu"
      },
      {
        "name": "Urząd Skarbowy w Wałczu"
      },
      {
        "name": "Zachodniopomorski Urząd Skarbowy w Szczecinie"
      }
    ]
  }

  componentDidMount() {

  }

  handleInputChange(event) {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    let name = target.name

    console.log(name, value)
    this.setState({
      [name]: value,
      fields: { ...this.state.fields, [name]: value },
    })
  }

  setError(er) {
    this.setState({error: er})
  }

  validate(e) {
    e.preventDefault();

    if (this.state.postalCode != "" && !/[0-9]{2}-[0-9]{3}/.test(this.state.postalCode)) {
      this.setError("Nieprawidłowy kod pocztowy")
    } else if (this.state.taxNumber != "" && !/[0-9]{10,11}/.test(this.state.taxNumber)) {
      this.setError("Nieprawidłowy PESEL/NIP")
    } else if (!moment(this.state.dob).isSameOrBefore(moment().subtract(18,'y'))) {
      this.setError("Nieprawidłowa data urodzenia")
    } else if (this.state.otp === "") {
      this.setError("Wpisz Kod sms")
    } else if (this.state.taxOfficeName === "") {
      this.setError("Wybierz Urząd Skarbowy")
    } else {
      this.setError("")
      this.props.updateUser({otp: this.state.otp, user: this.state.fields})
    }
  }

  render() {
    return (
      <div className="page profile">
        <section>
          <div className="page-header">
            <h1>Mój profil</h1>
            <p><a href="/" onClick={(e)=>{ e.preventDefault(); this.props.setPopup(true)}}>Twój numer BAT Partnera</a></p>
            <p><strong>Uzupełnij swoje dane, by wypłacać zarobione pieniądze.</strong></p>
            <p>Ze względów bezpieczeństwa niektórych danych pozwalających na identyfikację uczestnika programu nie można zmienić w zakładce Profil. Jeżeli któraś z tych danych uległa zmianie, wyślij nam wiadomość na adres <a href="mailto:kontakt@batpartners.pl">kontakt@batpartners.pl</a> i wskaż dane do zmiany – pomożemy!</p>
          </div>
        </section>
        <section className="login-section">
          <h4>Dane logowania</h4>
          <div className="input-item">
            <label htmlFor="email">E-mail</label>
            <input name="email" type="email" id="email" value={ this.props.user.user.emailAddress } disabled="disabled" />
          </div>
          <div className="password-item">
            <div className="label">Hasło</div>
            <p>Chcesz zmienić hasło? Kliknij w link poniżej i postępuj zgodnie z instrukcją, którą otrzymasz mailowo.</p>
            <NavLink to="/reset-hasla">Chcę zresetować hasło</NavLink>
          </div>
        </section>
        <section className="user-data-section">
          <div className="input-item">
            <label htmlFor="firstName">Imię</label>
            <input name="firstName" type="text" id="firstName" value={ this.state.firstName } disabled="disabled"></input>
          </div>
          <div className="input-item">
            <label htmlFor="lastName">Nazwisko</label>
            <input name="lastName" type="text" id="lastName" value={ this.state.lastName } disabled="disabled"></input>
          </div>
          <div className="input-item">
            <label htmlFor="mobilePhone">Numer telefonu</label>
            <input name="mobilePhone" type="text" id="mobilePhone" value={ this.props.user.user.mobilePhone } disabled="disabled"/>
          </div>
          <div className="input-item">
            <label htmlFor="streetAddress">Adres</label>
            <input name="streetAddress" type="text" id="streetAddress" value={ this.state.streetAddress } onChange={ (e)=>{ this.handleInputChange(e) } }></input>
          </div>
          <div className="input-item">
            <label htmlFor="postalCode">Kod pocztowy</label>
            <input name="postalCode" type="text" id="postalCode" value={ this.state.postalCode } onChange={ (e)=>{ this.handleInputChange(e) } }></input>
          </div>
          <div className="input-item">
            <label htmlFor="cityName">Miejscowość</label>
            <input name="cityName" type="text" id="cityName" value={ this.state.cityName } onChange={ (e)=>{ this.handleInputChange(e) } }></input>
          </div>
          <div className="input-item">
            <label htmlFor="dob">Data urodzenia</label>
            <input name="dob" type="date" id="dob" value={ this.state.dob } onChange={ (e)=>{ this.handleInputChange(e) } }></input>
          </div>
          <div className="input-item">
            <label htmlFor="taxNumber">PESEL lub NIP</label>
            <input name="taxNumber" type="text" id="taxNumber" value={ this.state.taxNumber } onChange={ (e)=>{ this.handleInputChange(e) } }></input>
          <div className="input-item">
            <label htmlFor="salesPoint">Kod punktu sprzedaży</label>
            <input name="salesPoint" type="text" id="salesPoint" value={ this.props.user.user.salesPoint } disabled="disabled"/>
          </div>

          </div>
        </section>
        <section className="user-data-section residency-section">
          <div className="checkbox-item" onClick={ ()=>{ this.setState({residency: !this.state.residency})}}>
            <div className="label-text">Jestem polskim rezydentem podatkowym</div>
            <div className={ this.state.residency ? "check active" : "check"} >{ this.state.residency ? "Tak" : "Nie" }</div>
          </div>
          { this.state.residency ?
            <div className="input-item">
              <label htmlFor="taxOfficeName">Nazwa Urzędu Skarbowego</label>
              {/*<input name="taxOfficeName" type="text" id="taxOfficeName" value={ this.state.taxOfficeName } onChange={ (e)=>{ this.handleInputChange(e) } }></input>*/}
              <select name="taxOfficeName" id="taxOfficeName" onChange={ (e)=>{ this.handleInputChange(e) } } value={ this.state.taxOfficeName }>
                <option value="">-- Wybierz z listy ---</option>
                { this.us.map((item, key)=>{
                  return <option key={'option-'+key} value={item.name}>{ item.name }</option>
                })}
              </select>
            </div>
            :
            <div className="input-item">
              <label htmlFor="foreignTaxResidence">Rezydencja podatkowa</label>
              <input name="foreignTaxResidence" type="text" id="foreignTaxResidence" value={ this.state.foreignTaxResidence } onChange={ (e)=>{ this.handleInputChange(e) } }></input>
            </div>
          }
          { this.state.residency ?
            <>
              <div className="checkbox-item" onClick={ ()=>{ this.setState({jdg: !this.state.jdg, fields: { ...this.state.fields, businessOwnership: !this.state.jdg } })}}>
                <div className="label-text">Prowadzę jednoosobową działalność gospodarczą</div>
                <div className={ this.state.jdg ? "check active" : "check"} >{ this.state.jdg ? "Tak" : "Nie" }</div>
              </div>
              { this.state.jdg ?
                <div className="input-item">
                  <label htmlFor="foreignTaxResidence">Numer PKD prowadzonej działalności</label>
                  <select name="foreignTaxResidence" id="foreignTaxResidence" onChange={ (e)=>{ this.handleInputChange(e) } } value={ this.state.foreignTaxResidence }>
                      <option value="">-- Wybierz z listy ---</option>
                      <option value={'4635Z 46.35.Z  - Sprzedaż hurtowa wyrobów tytoniowych'}>4635Z 46.35.Z  - Sprzedaż hurtowa wyrobów tytoniowych</option>
                      <option value={'Inny'}>Inny</option>
                  </select>
                  { this.state.foreignTaxResidence === '4635Z 46.35.Z  - Sprzedaż hurtowa wyrobów tytoniowych' ?<p>Drogi BAT Partnerze pamiętaj, aby rozliczyć PIT samodzielnie!</p> : '' }
                </div>
                : null
              }
            </>
            : null
          }
        </section>
        <section className="user-data-section save-section">
          <p><strong>Autoryzacja i zapisywanie zmiany danych</strong></p>
          <p>Żeby potwierdzić zmianę danych, kliknij „Wyślij mi kod autoryzacyjny”, wprowadź kod otrzymany SMSem, a następnie wybierz „Zapisz”.</p>
          <p>
            <a className="sms-code-btn" href="#" onClick={ (e)=> {
              e.preventDefault();
              this.props.otp()
            }}>Wyślij mi kod autoryzacyjny</a>
          </p>
          <div className="input-item">
            <label htmlFor="otp">Kod autoryzacyjny</label>
            <input name="otp" type="text" id="otp" value={ this.state.otp } onChange={ (e)=>{ this.setState({ otp: e.target.value }) }}></input>
          </div>
          { this.state.error !== "" ? <p className="error">{ this.state.error }</p> : null }
          <div className="center">
            <button className="btn" onClick={ (e) => { this.validate(e) } }>Zapisz</button>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    otp,
    updateUser
  }
)(Profile));