import React, { useState } from 'react';
import { NavLink ,Redirect,useHistory} from "react-router-dom";
import BottomModal from './BottomModal';
import Button from './Button';

import { ReactComponent as IconExclamation } from '../../img/exclamation_icon.svg';
import { ReactComponent as IconLocked } from '../../img/locked_icon.svg';
import { ReactComponent as IconPrize } from '../../img/icon-prize.svg';
import { ReactComponent as IconHourglass } from '../../img/icon-hourglass.svg';
import { ReactComponent as IconChecked } from '../../img/icon-hourglass.svg';
function BottomError(props) {
  const history = useHistory();

  const content = () => {
    switch(props.message) {
      case "error_wrong_serial_number_limit_exceeded":
        return (
        <div className="popup-inner popup-error">
          <IconLocked/>
          <h2>Niepoprawny numer seryjny urządzenia</h2>
          <p className="color-red">Twoje konto zostało czasowo zablokowane. Skontaktuj się z infolinią pod numerem 800&nbsp;610&nbsp;610.</p>
          <Button action={ () => { props.setPopup(false) }} >Rozumiem</Button>
        </div>
      )
      case "error_zero_results":
        return (
        <div className="popup-inner popup-error">
          <IconExclamation />
          <h2>Brak wyników</h2>
          <p className="color-red">Nie udało się wykryć prawidłowego numeru. Spróbuj ponownie.</p>
          <Button action={ () => { props.setPopup(false) }} >Ok</Button>
        </div>
      )
      case "congratulations":
          return (
            <div className="popup-inner popup-info popup-info--quiz">
              <IconChecked />
              <h2>Gratulacje</h2>
              <p>Twój poziom wiedzy jest na wysokim poziomie. Udało Ci się odpowiedzieć poprawnie na wszystkie pytania.</p>
              <span className="color-orange">Otrzymujesz 5 zł!</span>
              <Button action={ () => { 
                props.setPopup(false);
                history.push('/dashboard');
                }
                } >Sprawdź inne zadania</Button>
            </div>
        )

      case "error_activation_waiting_period": 
        return (
          <div className="popup-inner popup-info popup-info--quiz">
            <IconHourglass />
            <h2>Nie udało się dziś,<br/> uda się jutro!</h2>
            <p>Wróć za 24h i spróbuj ponownie rozwiązać quiz.</p>
            <p>Tym razem będziemy trzymać kciuki jeszcze mocniej.</p>
            <Button action={ () => {      
              props.setPopup(false);
              history.push('/dashboard'); 
              }} >Sprawdź inne zadania</Button>
          </div>
        )
        
      default:
        return (
          <div className="popup-inner popup-error">
            <IconExclamation />
            <h2>Niepoprawny numer seryjny urządzenia</h2>
            <p>Zweryfikuj, czy poprawnie wprowadziłeś numer urządzenia.</p>
            <p>Jeżeli wprowadzony numer jest zgodny z tym na pudełku – zrób zdjęcie numeru urządzenia i prześlij je na adres <a href="mailto:kontakt@batpartners.pl">kontakt@batpartners.pl</a>.</p>
            <p>Nie martw się – na podstawie zdjęcia numeru będziesz mógł zarejestrować to urządzenie później. </p>
            <Button action={ () => { props.setPopup(false) }} >Rozumiem</Button>
          </div>
        )
    }
  }
  return (
    props.redirect ? (
      <div className="bottom-error">
			<BottomModal open={ props.popup } 
      hidePopup={ 
        ()=>{ props.setPopup(false);
          history.push(props.redirect); 
         } }>
        { content()}
      </BottomModal>
    </div>
      ) : (
      <div className="bottom-error">
        <BottomModal open={ props.popup } hidePopup={ ()=>{ props.setPopup(false) } }>
          { content()}
        </BottomModal>
      </div>
     )
  );
}

export default BottomError;
