import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { NavLink, useHistory } from 'react-router-dom';
import { resetPass } from '../../actions/user';
import Button from '../Button'

function NewPasswordPage(props) {
  const history = useHistory();
  const [pass, setPass] = useState("");
  const [pass2, setPass2] = useState("");
  const [error, setError] = useState("");

  let validate = (e) => {
    e.preventDefault()

    if (pass === "") {
      setError("Wpisz hasło")
    } else if (pass2 === "") {
      setError("Powtórz hasło")
    } else if (pass != pass2) {
      setError("Wpisane hasła nie są identyczne")
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\?<>()\[\]\{\}+\-_\/\\:;"'|=]).{8,}$/.test(pass)) {
      setError("Min. 8 znaków, 1 duża litera, 1 mała litera, 1 cyfra, 1 znak specjalny")
    } else {
      setError("")
      props.resetPass({user: { password: pass ,sourceHoreca:true}}, history)
      if (window.gtag) {
        window.gtag('event', "click", { event_category: "bp-button-confirm", event_label: 'click' })
      }
    }
  }

  return (
    <section className="remind-page new-pass-page">
    	<div className="page-inner">
        <h1>Ustaw nowe hasło</h1>
        <p className="color-orange bold">Min. 8 znaków, 1 duża litera, 1 mała litera, 1 cyfra, 1 znak specjalny</p>
        <form>
          <div className="input-item">
            <label htmlFor="password">Hasło</label> 
            <input type="password" id="password" name="password" value={ pass } onChange={ (e) => { setPass(e.target.value) } }/>
          </div>
          <div className="input-item">
            <label htmlFor="password2">Powtórz hasło</label> 
            <input type="password" id="password2" name="password2" value={ pass2 } onChange={ (e) => { setPass2(e.target.value) } }/>
          </div>
          { error !== "" ? <p className="error">{ error }</p> : null }

          <div className="login-link">
            <NavLink to="/">Powrót do logowania</NavLink>
          </div>
          <div className="center">
            <Button className="btn" action={ (e) => { validate(e) } }>Zapisz</Button>
          </div>
        </form>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    resetPass
  }
)(NewPasswordPage));