import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import Button from '../../Button';
import Image from "../../../../img/superseria_img@2x.png";

function SuperseriaUltimate(props) {
  const history = useHistory();

  let validate = (e) => {
    e.preventDefault()

  }

  return (
    <div className="page activation-page">
      <section>
          <div className="page-header">
            <h1>Superseria - Ultimate</h1>
            <p>Sprzedaj <strong>pietnaście urządzeń BAT</strong> w czasie trwania sezonu, osiągnij rangę Ultimate i odbierz <strong>dodatkowe 60 zł!</strong></p>
            <p><strong>Powodzenia!</strong></p>
            <img className="activation-image" src={ Image } />
          </div>
        </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { screen, user, activations } = state
  return { screen, user, activations }
};

export default withRouter(connect(
  mapStateToProps,
  {

  }
)(SuperseriaUltimate));