import React, { useState } from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import { ReactComponent as IconActivities } from '../../img/icon-activities-white.svg'
import { ReactComponent as IconDashboard } from '../../img/icon-dashboard-white.svg'
import { ReactComponent as IconClose } from '../../img/icon-close-primary.svg'
import BottomModal from './BottomModal';

function BottomNav(props) {

	let checkCompleted = (type) => {
		if (props.activations.list) {
			let activation = props.activations.list.filter((item, key) => {
				return item.ident === type
			})

			if (activation.length > 0 ) {
				if (activation[0].completed != null) {
					return activation[0].completed ? 'active' : '';
				} else {
					if (activation[0].currentNumberOfSolutions === 1) {
						return 'active';
					} else {
						return '';
					}
				}
			} else {
				return '';
			}
		} else {
			return '';
		}
	}

	let getQuarter = () => {
		let today = new Date();
		let quarter = Math.floor((today.getMonth() / 3)) + 1;
		let year = today.getFullYear().toString().substr(-2);
		return `q${quarter}_${year}`;
	}

  return (
    <div className="bottom-nav">
    	<ul>
    		<li>
    			<div className="bottom-nav-item" onClick={ ()=>{ props.setPopup(true) } }>
    				<div className="icon"><IconActivities/></div>
    				<span>Moje aktywności</span>
    			</div>
    		</li>
    		<li>
    			<NavLink to="/nowa-sprzedaz">
    				<div className="new-sale-icon"></div>
    				<span>Nowa sprzedaż</span>
    			</NavLink>
    		</li>
    		<li>
    			<NavLink to="/dashboard">
    				<div className="icon"><IconDashboard/></div>
    				<span>Dashboard</span>
    			</NavLink>
    		</li>
    	</ul>
			<BottomModal open={ props.popup } hidePopup={ ()=>{ props.setPopup(false) } }>
        <div className="popup-inner popup-activities">
					<h2>Moje aktywności</h2>
					<ul className="activities-list">
						<li className={ checkCompleted('super_seria_lvl1_'+getQuarter()) }><NavLink to="/aktywacje/superseria" onClick={()=>{ props.setPopup(false) }}>Superseria - Początkujący</NavLink> <span className="score">15zł</span></li>
						<li className={ checkCompleted('super_seria_lvl2_'+getQuarter()) }><NavLink to="/aktywacje/superseria-pro" onClick={()=>{ props.setPopup(false) }}>Superseria - Profesjonalista</NavLink> <span className="score">40zł</span></li>
						<li className={ checkCompleted('super_seria_lvl3_'+getQuarter()) }><NavLink to="/aktywacje/superseria-top" onClick={()=>{ props.setPopup(false) }}>Superseria - Top Class</NavLink> <span className="score">50zł</span></li>
						<li className={ checkCompleted('super_seria_lvl4_'+getQuarter()) }><NavLink to="/aktywacje/superseria-ultimate" onClick={()=>{ props.setPopup(false) }}>Superseria - Ultimate</NavLink> <span className="score">60zł</span></li>
						<li className={ checkCompleted('super_seria_lvl5_'+getQuarter()) }><NavLink to="/aktywacje/superseria-hero" onClick={()=>{ props.setPopup(false) }}>Superseria - Hero</NavLink> <span className="score">100zł</span></li>
						<li className={ checkCompleted('fill_profile') }><NavLink to="/aktywacje/uzupelnij-profil" onClick={()=>{ props.setPopup(false) }}>Uzupełnij profil</NavLink> <span className="score">5zł</span></li>
						<li className={ checkCompleted('quiz') }><NavLink to="/aktywacje/quiz-season-2" onClick={()=>{ props.setPopup(false) }}>Quiz</NavLink> <span className="score">5 zł</span></li>
					</ul>
				</div>
      </BottomModal>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { screen, activations } = state
  return { screen, activations }
};

export default withRouter(connect(
  mapStateToProps,
  {
    
  }
)(BottomNav));
