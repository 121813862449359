import React from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import Button from '../../Button';
import { ReactComponent as IconPrize } from '../../../../img/ic-gift.svg';
import { ReactComponent as IconProduct } from '../../../../img/ic-products.svg';
import { ReactComponent as IconPig } from '../../../../img/ic-zarabiaj.svg';

function NotificationNewSeasonSales(props)  {

  return (
    <div className="page notification">
      <section>
        <div className="page-header">
          <h1>Premiera drugiego sezonu BAT Partners!</h1>
          <h2 className="orange">01.05.2021 - 31.07.2021</h2>
          <p>Czas wziąć udział w nowym etapie.</p>
          <p>W poprzednim sezonie udało Ci się sprzedać <strong>{ props.account.seasons[1].soldDevices.all+' urządzeń'}</strong>. Brawo!</p>
          <p>Myślisz, że teraz uda Ci się utrzymać ten sam poziom? Albo jeszcze lepiej… pobić swój poprzedni wynik?</p>
          <IconPrize className="icon centered"/>
          <h2 className="orange">To idealny moment, aby zgarnąć nowe nagrody.</h2>
          <p>Oznacza to, że wszyscy rozpoczynają nowy sezon od początkowej Rangi. Dodatkowo od 1 maja możesz walczyć o 500 zł w nowych Rankingach Sprzedaży i Poleceń.</p>
          <IconProduct className="icon centered"/>
          <h2 className="orange">Jak dobrze znasz nasze produkty?</h2>
          <p>W maju na stronie programu pojawią się dodatkowe aktywności i kolejne szanse na zdobycie nagród – bądź czujny!</p>
          <h2>Ciągle się wahasz?</h2>
          <IconPig className="icon centered"/>
          <h2 className="orange">Polecaj, zarabiaj i wypłacaj!</h2>
          <p>Sprzedawaj urządzenia BAT i skanuj ich numery na stronie programu poprzez swój smartfon– już wkrótce do zdobycia pojawią się dodatkowe nagrody pieniężne za skanowanie sprzedanych urządzeń, spełnij określone warunki i zyskuj (szczegóły już wkrótce)!</p>
          <p>Zlecaj wypłatę jednym kliknięciem i odbieraj pieniądze wtedy, kiedy masz ochotę*.</p>
          <p style={{fontSize: '12px'}}>* Zgodnie z zasadami platformy Billon</p>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { screen, user, account } = state
  return { screen, user, account }
};

export default withRouter(connect(
  mapStateToProps,
  {

  }
)(NotificationNewSeasonSales));