import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { register } from '../../actions/user';
import { NavLink } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import Button from '../Button'
import { ReactComponent as IconBack } from '../../../img/icon-back.svg'
import { registerLocale } from "react-datepicker";

function RegisterPage(props) {
  const [formStep, setFormStep] = useState(0)
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [sellpoint, setSellpoint] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [hostessCode, setHostessCode] = useState("");
  const [pass, setPass] = useState("");
  const [pass2, setPass2] = useState("");
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const [ageConsent, setAgeConsent] = useState(false);
  const [regulationsConsent, setRegulationsConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [emailContact, setEmailContact] = useState(false);
  const [phoneContact, setPhoneContact] = useState(false);
  const history = useHistory();

  const validatePhonePrefix = (phone, required) => {
    if (required === false) {
        if (phone === "") {
            return true;
        }
    }
 
    let tel = phone;
 
    if (tel.length > 4) {
        const prefixes3 = [
            "450",
            "459",
            "500",
            "501",
            "502",
            "503",
            "504",
            "505",
            "506",
            "507",
            "508",
            "509",
            "510",
            "511",
            "512",
            "513",
            "514",
            "515",
            "516",
            "517",
            "518",
            "519",
            "530",
            "531",
            "532",
            "533",
            "534",
            "535",
            "537",
            "538",
            "539",
            "570",
            "572",
            "574",
            "575",
            "576",
            "577",
            "578",
            "600",
            "601",
            "602",
            "603",
            "604",
            "605",
            "606",
            "607",
            "608",
            "609",
            "660",
            "661",
            "662",
            "663",
            "664",
            "665",
            "667",
            "668",
            "669",
            "691",
            "692",
            "693",
            "694",
            "695",
            "696",
            "697",
            "698",
            "721",
            "722",
            "723",
            "724",
            "725",
            "726",
            "730",
            "731",
            "732",
            "733",
            "734",
            "735",
            "738",
            "781",
            "782",
            "784",
            "785",
            "730",
            "788",
            "790",
            "791",
            "792",
            "794",
            "796",
            "797",
            "798",
            "880",
            "882",
            "885",
            "886",
            "887",
            "888",
            "889",
        ];
        let prefix3 = tel.substring(0, 3);
 
        const prefixes4 = [
            "5360",
            "5361",
            "5362",
            "5363",
            "5364",
            "5365",
            "5366",
            "5367",
            "5368",
            "5369",
            "5711",
            "5712",
            "5713",
            "5714",
            "5730",
            "5731",
            "5732",
            "5733",
            "5734",
            "5739",
            "5790",
            "5791",
            "5792",
            "5793",
            "5796",
            "5797",
            "5798",
            "5799",
            "6660",
            "6661",
            "6662",
            "6663",
            "6664",
            "6665",
            "6666",
            "6667",
            "6668",
            "6669",
            "6900",
            "6901",
            "6902",
            "6903",
            "6904",
            "6905",
            "6906",
            "6908",
            "6909",
            "6991",
            "6993",
            "6994",
            "6998",
            "6999",
            "7200",
            "7201",
            "7202",
            "7203",
            "7204",
            "7205",
            "7206",
            "7207",
            "7208",
            "7209",
            "7270",
            "7271",
            "7272",
            "7273",
            "7274",
            "7275",
            "7276",
            "7277",
            "7278",
            "7279",
            "7280",
            "7281",
            "7282",
            "7283",
            "7284",
            "7285",
            "7286",
            "7287",
            "7288",
            "7289",
            "7290",
            "7291",
            "7292",
            "7293",
            "7294",
            "7295",
            "7296",
            "7298",
            "7299",
            "7360",
            "7361",
            "7362",
            "7363",
            "7364",
            "7365",
            "7366",
            "7367",
            "7368",
            "7369",
            "7370",
            "7371",
            "7372",
            "7373",
            "7374",
            "7375",
            "7376",
            "7377",
            "7378",
            "7379",
            "7390",
            "7391",
            "7392",
            "7394",
            "7395",
            "7396",
            "7397",
            "7398",
            "7800",
            "7801",
            "7808",
            "7830",
            "7831",
            "7832",
            "7833",
            "7834",
            "7835",
            "7836",
            "7837",
            "7838",
            "7839",
            "7861",
            "7862",
            "7863",
            "7864",
            "7865",
            "7866",
            "7867",
            "7868",
            "7869",
            "7870",
            "7871",
            "7872",
            "7873",
            "7874",
            "7875",
            "7876",
            "7877",
            "7878",
            "7879",
            "7890",
            "7891",
            "7892",
            "7893",
            "7894",
            "7895",
            "7896",
            "7897",
            "7898",
            "7899",
            "7930",
            "7931",
            "7932",
            "7933",
            "7934",
            "7935",
            "7936",
            "7937",
            "7938",
            "7939",
            "7950",
            "7951",
            "7952",
            "7953",
            "7954",
            "7955",
            "7956",
            "7957",
            "7958",
            "7959",
            "7990",
            "7991",
            "7992",
            "7993",
            "7994",
            "7995",
            "7997",
            "7998",
            "7999",
            "8810",
            "8811",
            "8812",
            "8813",
            "8814",
            "8815",
            "8816",
            "8817",
            "8818",
            "8819",
            "8822",
            "8830",
            "8831",
            "8832",
            "8833",
            "8834",
            "8835",
            "8836",
            "8837",
            "8838",
            "8839",
            "8840",
            "8841",
            "8842",
            "8843",
            "8844",
            "8845",
            "8846",
            "8847",
            "8848",
            "8849",
        ];
        let prefix4 = tel.substring(0, 4);
 
        const prefixes5 = [
            "69900",
            "69901",
            "69902",
            "69903",
            "69904",
            "69905",
            "69906",
            "69907",
            "69908",
            "69909",
            "69920",
            "69921",
            "69922",
            "69923",
            "69924",
            "69925",
            "69926",
            "69927",
            "69928",
            "69929",
            "69950",
            "69951",
            "69952",
            "69953",
            "69954",
            "69955",
            "69956",
            "69957",
            "69958",
            "69959",
            "699560",
            "699561",
            "699562",
            "699563",
            "699564",
            "699565",
            "699566",
            "69957",
            "699568",
            "699569",
            "699570",
            "699571",
            "699572",
            "699573",
            "699574",
            "699575",
            "699576",
            "699577",
            "699578",
            "699579",
            "72970",
            "72971",
            "72972",
            "72973",
            "72974",
            "72975",
            "72976",
            "72977",
            "72978",
            "72979",
            "73930",
            "73931",
            "73932",
            "73933",
            "73934",
            "73935",
            "73936",
            "73937",
            "73938",
            "73939",
            "73991",
            "73992",
            "73993",
            "78020",
            "78021",
            "78022",
            "78023",
            "78024",
            "78025",
            "78026",
            "78027",
            "78028",
            "78029",
            "78608",
        ];
        let prefix5 = tel.substring(0, 5);
 
        if (prefixes3.indexOf(prefix3) >= 0 || prefixes4.indexOf(prefix4) >= 0 || prefixes5.indexOf(prefix5) >= 0) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

  let validateStepOne = (e) => {
    e.preventDefault()

    if (firstname === "") {
      setError("Wpisz imię")
    } else if (lastname === "") {
      setError("Wpisz nazwisko")
    } else if (sellpoint === "") {
      setError("Wpisz kod punktu sprzedaży")
    } else if (!/^(HRC)(\d{6,14})$/.test(sellpoint)) {
      setError("Kod punktu jest nieprawidłowy")
    } else {
      setError("")
      setFormStep(1);
    }
  }

  let validateStepTwo = (e) => {
    e.preventDefault()

    if (email === "") {
      setError("Wpisz adres email")
    } else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
      setError("Nieprawidłowy adres email")
    } else if (phone === "") {
      setError("Wpisz numer telefonu")
    } else if (!validatePhonePrefix(phone) || phone.length < 9 || phone.length > 12) {
      setError("Nieprawidłowy numer telefonu")
    } else if (!ageConsent || !regulationsConsent) {
      setError("Zaznacz wymagane zgody")
    } else if (hostessCode === "") {
      setError("Wpisz kod osoby polecającej");
    } else if (hostessCode != '' && !/^(D2S)(\d{3,4})$/.test(hostessCode)) {
      setError("Nieprawidłowy kod polecający");
    } else {
      setError("")
      //setFormStep(2);
      register();
      if (window.gtag) {
        window.gtag('event', "click", { event_category: "bp-button-register", event_label: 'click' })
      }
    }
  }

  let validateStepThree = (e) => {
    e.preventDefault()

    if (pass === "") {
      setError("Wpisz hasło")
    } else if (pass2 === "") {
      setError("Powtórz hasło")
    } else if (pass != pass2) {
      setError("Wpisane hasła nie są identyczne")
    } else {
      setError("")
    }
  }

  let register = () => {
    let mobilephone = phone;
    if ( mobilephone.indexOf('+48') === -1 ) {
      mobilephone = '+48'+mobilephone
    }
    let data = {
      "firstName": firstname,
      "lastName": lastname,
      "salesPoint": sellpoint,
      "emailAddress": email,
      "mobilePhone": mobilephone,
      "ageVerified": ageConsent,
      "consentAgreement": regulationsConsent,
      "marketingConsent": marketingConsent,
      "phoneContact": phoneContact,
      "emailContact": emailContact,
      "hostessCode": hostessCode,
      "sourceHoreca": true
    }
    
    props.register(data, history)
    //setFormStep(2);
  }

  return (
    <section className="register-page">
      <div className="register-page-head">
        { formStep > 0 ? <div className="back-icon" onClick={ ()=>{ setFormStep(formStep-1) }}><IconBack /></div> : <div className="back-icon"><NavLink to="/"><IconBack /></NavLink></div> }
        <ul className="register-page-nav">
          <li className={formStep >= 0 ? "active" : ""}></li>
          <li className={formStep >= 1 ? "active" : ""}></li>
        </ul>
      </div>
    	<div className="page-inner">
  			<h1>Witaj w programie <br/>BAT Partners</h1>
  			<p className="color-orange bold">Zarejestruj się</p>
  			<form>
          <div className={formStep === 0 && !props.user.registerSuccess ? "form-step active": "form-step"}>
            <div className="input-item">
              <label htmlFor="firstname">Imię</label>
              <input type="text" id="firstname" name="firstname" value={ firstname } onChange={ (e) => { setFirstname(e.target.value) } }/>
            </div>
            <div className="input-item">
              <label htmlFor="lastname">Nazwisko</label>
              <input type="text" id="lastname" name="lastname" value={ lastname } onChange={ (e) => { setLastname(e.target.value) } }/>
            </div>
            <div className="input-item">
              <label htmlFor="sellpoint">Kod punktu sprzedaży*</label>
              <input type="text" id="sellpoint" name="sellpoint" value={ sellpoint } onChange={ (e) => { setSellpoint(e.target.value.toUpperCase()) } }/>
            </div>
            { error !== "" ? <p className="error">{ error }</p> : null }

            <div className="login-link">
              <NavLink to="/">Powrót do logowania</NavLink>
            </div>
            <p className="small">*Aby otrzymać informację na temat kodu swojego punktu sprzedaży, skontaktuj się z Przedstawicielem BAT, który odwiedza Twój punkt sprzedaży.</p>
            <div className="center">
              <Button className="btn" action={ (e) => { validateStepOne(e) } }>Dalej</Button>
            </div>

            <p className="small">Administratorem danych wskazanych w trakcie rejestracji jest British American Tobacco Polska Trading sp. z o.o. z siedzibą w Warszawie przy ul. Krakowiaków 48. Skontaktuj się z nami telefonicznie pod numerem 800 610 610 lub mailowo na adres <a href="mailto:daneosobowe@bat.com.pl">daneosobowe@bat.com.pl</a> Dane będą przez nas wykorzystywane dla celów związanych z Twoim uczestnictwem w programie BAT Partners, w tym utworzenia Twojego konta uczestnika. W związku z podaniem danych osobowych przysługuje Ci prawo dostępu do treści Twoich danych, prawo ich poprawiania, usunięcia lub przenoszenia, a także prawo do żądania ograniczenia ich przetwarzania. Przysługuje Ci także prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych. Więcej informacji znajdziesz w informacji o przetwarzaniu danych na końcu <a href="/assets/regulamin_bat_partners_horeca.pdf" target="_blank">Regulaminu</a>.<br/><br/>
            W razie pytań lub problemów technicznych napisz do nas na adres <a href="mailto:kontakt@batpartners.pl">kontakt@batpartners.pl</a></p>
          </div>
          <div className={formStep === 1  && !props.user.registerSuccess ? "form-step active": "form-step"}>
            <div className="input-item">
              <label htmlFor="email">E-mail</label>
              <input type="email" id="email" name="username" value={ email } onChange={ (e) => { setEmail(e.target.value) } }/>
            </div>
            <div className="input-item">
              <label htmlFor="phone">Numer telefonu</label>
              <p>(Ten numer będzie potrzebny do odbioru nagród)</p>
              <input type="text" id="phone" name="phone" value={ phone } onChange={ (e) => { setPhone(e.target.value) } }/>
            </div>
            <div className="input-item">
              <label htmlFor="hostessCode">Kod osoby polecającej</label>
              <input type="text" id="hostessCode" name="hostessCode" value={ hostessCode } onChange={ (e) => { setHostessCode(e.target.value) } }/>
            </div>
            { error !== "" ? <p className="error">{ error }</p> : null }

            <div className="login-link">
              <NavLink to="/">Powrót do logowania</NavLink>
            </div>

            <div className="checkbox-item">
              <input type="checkbox" id="bp_age_consent" name="bp_age_consent" checked={ ageConsent } onChange={ (e) => { setAgeConsent(!ageConsent) } }/>
              <label htmlFor="bp_age_consent">Potwierdzam, że jestem osobą pełnoletnią i jestem uprawniony/a do wzięcia udziału w Programie zgodnie z postanowieniami Regulaminu*</label>
            </div>
            <div className="checkbox-item">
              <input type="checkbox" id="bp_regulations_consent" name="bp_regulations_consent" checked={ regulationsConsent } onChange={ (e) => { setRegulationsConsent(!regulationsConsent) } }/>
              <label htmlFor="bp_regulations_consent">Akceptuję warunki Programu określone w <a href="/assets/regulamin_bat_partners_horeca.pdf" target="_blank">Regulaminie Programu</a>*</label>
            </div>
            <div className="checkbox-item">
              <input type="checkbox" id="bp_marketing_consent" name="bp_marketing_consent" checked={ marketingConsent } onChange={ (e) => { setMarketingConsent(!marketingConsent) } }/>
              <label htmlFor="bp_marketing_consent">Wyrażam zgodę na przetwarzanie moich danych osobowych przez British American Tobacco Polska Trading sp. z o.o. z siedzibą w Warszawie (BAT) w celu przesyłania mi informacji na temat produktów i usług oferowanych przez BAT. </label>
            </div>
            <p className="small" style={{ margin: '.5em 0 1em'}}>Jako formę kontaktu na potrzeby przesyłania informacji na temat produktów i usług BAT wybieram:</p>
            <div className="checkbox-item">
              <input type="checkbox" id="bp_email_contact" name="bp_email_contact" checked={ emailContact } onChange={ (e) => { setEmailContact(!emailContact) } }/>
              <label htmlFor="bp_email_contact">wiadomości przesyłane drogą elektroniczną (e-mail, SMS, serwisy internetowe, okienka pop up)</label>
            </div>
            <div className="checkbox-item">
              <input type="checkbox" id="bp_phone_contact" name="bp_phone_contact" checked={ phoneContact } onChange={ (e) => { setPhoneContact(!phoneContact) } }/>
              <label htmlFor="bp_phone_contact">połączenia głosowe (rozmowy telefoniczne, komunikaty IVR) </label>
            </div>
            <p className="small">* Zgody konieczne do wzięcia udziału w Programie.</p>
            <div className="center">
              <Button className="btn" action={ (e) => { validateStepTwo(e) } }>Dalej</Button>
            </div>
            <p className="small">Administratorem danych wskazanych w trakcie rejestracji jest British American Tobacco Polska Trading sp. z o.o. z siedzibą w Warszawie przy ul. Krakowiaków 48. Skontaktuj się z nami telefonicznie pod numerem 800 610 610 lub mailowo na adres <a href="mailto:daneosobowe@bat.com.pl">daneosobowe@bat.com.pl</a> Dane będą przez nas wykorzystywane dla celów związanych z Twoim uczestnictwem w programie BAT Partners, w tym utworzenia Twojego konta uczestnika. W związku z podaniem danych osobowych przysługuje Ci prawo dostępu do treści Twoich danych, prawo ich poprawiania, usunięcia lub przenoszenia, a także prawo do żądania ograniczenia ich przetwarzania. Przysługuje Ci także prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych. Więcej informacji znajdziesz w informacji o przetwarzaniu danych na końcu <a href="/assets/regulamin_bat_partners_horeca.pdf" target="_blank">Regulaminu</a>.<br/><br/>
            W razie pytań lub problemów technicznych napisz do nas na adres <a href="mailto:kontakt@batpartners.pl">kontakt@batpartners.pl</a></p>
          </div>
          <div className={props.user.registerSuccess ? "form-step active": "form-step"}>
            <p>Na Twój adres e-mail wysłaliśmy link potwierdzając. Kliknij w niego aby ustawić hasło i dokończyć rejestrację.</p>
            <div className="login-link">
              <NavLink to="/">Powrót do logowania</NavLink>
            </div>
          </div>
        </form>
  		</div>
    </section>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    register
  }
)(RegisterPage));