import React from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import Button from '../../Button';

function NotificationNewTerms2(props)  {

  return (
    <div className="page notification">
      <section>
        <div className="page-header">
          <h1>Drogi BAT Partnerze!</h1>
          <p>Dokonaliśmy zmian w regulaminie programu. <br/>Zmiany dotyczą <strong>§1 pkt. 14 oraz §4 pkt. 5</strong>. Prosimy o zapoznanie się ze zmianami</p>
          <a className="btn" href="/assets/regulamin_bat_partners_2021_21_05.pdf" target="_blank">Regulamin BAT Partners</a>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {

  }
)(NotificationNewTerms2));