import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { NavLink, useHistory } from 'react-router-dom';
import { requestResetPass } from '../../actions/user';
import { ReactComponent as IconCheck } from '../../../img/check.svg'

function ResetPassPage(props) {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  let validate = (e) => {
    e.preventDefault()

    if (email === "") {
      setError("Wpisz adres email")
    } else {
      props.requestResetPass(email, history)
    }
  }

  return (
    <section className="remind-page">
    	<div className="page-inner">
			<h1>Resetuj hasło</h1>
			<form>
        <section className={ !props.user.remindSuccess ? "form-step active": "form-step" }>
          <div className="center">
            <input type="email" placeholder="email" name="email" value={ email } onChange={ (e) => { setEmail(e.target.value) } }/>
          </div>
          { error !== "" ? <p className="error">{ error }</p> : null }
          <div className="center">
            <button className="btn" onClick={ (e) => { validate(e) } }>Dalej</button>
          </div>
        </section>
        <section className={ props.user.remindSuccess ? "form-step active": "form-step" }>
          <p>{"Wszystko jest na dobrej drodze. Na Twoją skrzynkę mailową ("+email+") wysłaliśmy instrukcję resetowania hasła."} </p>
          <div className="center">
            <IconCheck />
          </div>
          <NavLink className="btn" to="/profil">Powrót</NavLink>
        </section>
      </form>
		</div>
    </section>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    requestResetPass
  }
)(ResetPassPage));