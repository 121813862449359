import React from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import Button from '../../Button';

function NotificationHello(props)  {

  return (
    <div className="page notification">
      <section>
        <div className="page-header">
          <h1>Witaj w programie BAT Partners!</h1>
          <p>Poznajmy się lepiej – uzupełnij swój profil i zgarnij dodatkowe 5 zł do wypłaty już po pierwszym sprzedanym urządzeniu!</p>
          <Button to="/profil">Przejdź do profilu</Button>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {

  }
)(NotificationHello));