import React from 'react';
import BottomModal from './BottomModal';
import { ReactComponent as IconPrize } from '../../img/icon-bell-large.svg';
import { NavLink } from "react-router-dom";

function BottomInfo(props) {

  return (
    <div className="bottom-code">
			<BottomModal open={ props.popup } hidePopup={ ()=>{ props.setPopup(false) } }>
        <div className="popup-inner popup-info">
          <IconPrize/>
          <h2>Powiadomienia<br/> - bądź na bieżąco</h2>
          <p>Wzbogaciliśmy BAT Partners o nową funkcję. Wiemy, że nie zawsze masz czas na śledzenie wszystkich zmian i nowości, które dzieją się w programie. Być może ten dzwonek nie obudzi Cię do pracy, ale z&nbsp;pewnością dzięki niemu już nigdy nie przegapisz żadnej dodatkowej aktywności.</p>
          <p><strong>Mamy nadzieję, że ta mała zmiana przypadnie Ci&nbsp;do&nbsp;gustu.</strong></p>
          <NavLink className="btn" to="/dashboard" onClick={ () => { props.setPopup(false) }} >Rozumiem</NavLink>
        </div>
      </BottomModal>
    </div>
  );
}

export default BottomInfo;