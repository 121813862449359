import {
    LOGIN,
    LOGOUT,
    REMIND,
    SESSION,
    SET_TOKEN,
    SET_OTP_TOKEN,
    REGISTER_SUCCESS,
    REGISTER_SUCCESS_CLEAR,
    REQUEST_RESET_PASS,
    WELCOME_SCREEN_SHOWN,
    GET_MESSAGES_SUCCESS,
    ON_LOGIN_POPUP_OPEN,
    ON_LOGIN_POPUP_CLOSE
  } from "../actions/types";
  
  export default function(state = {}, action) {
    switch (action.type) {
      case LOGIN:
        return {
          ...state,
          token: action.payload.token,
        }
      case SESSION:
          return {
            ...state,
            user: action.payload.user
          }
      case LOGOUT:
          return {
              ...state,
              token: null,
              user: null
          }
      case REMIND:
          return {
              ...state,
              remindEmail: action.payload,
          }
      case SET_TOKEN:
        return {
          ...state,
          reset_token: action.payload.token,
        }
      case SET_OTP_TOKEN:
        return {
          ...state,
          otp_token: action.payload.token,
        }
      case REGISTER_SUCCESS:
        return {
          ...state,
          registerSuccess: true
        }
      case REQUEST_RESET_PASS:
        return {
          ...state,
          remindSuccess: true
        }
      case REGISTER_SUCCESS_CLEAR:
        return {
          ...state,
          registerSuccess: false,
          remindSuccess: false
        }
      case WELCOME_SCREEN_SHOWN:
        return {
          ...state,
          welcomeShow: false
        }
      case GET_MESSAGES_SUCCESS:
        let show = false;
        let tutMessage = action.payload.messages.filter((item, key)=>{
          return item.ident === "hello_tutorial"
        })

        if (tutMessage.length > 0 && tutMessage[0].seen === null) {
          show = true;
        }
        
        return {
          ...state,
          messages: action.payload.messages,
          welcomeShow: show 
        }
      case ON_LOGIN_POPUP_OPEN:
        return {
          ...state,
          onLoginPopup: true
        }
      case ON_LOGIN_POPUP_CLOSE:
        return {
          ...state,
          onLoginPopup: false
        }
      default:
        return state;
    }
  }